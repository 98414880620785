import { inject } from 'vue'
import { useNotification } from '@/composables/useNotification.js'

export function useZap() {
  const { showNotification } = useNotification()

  const supabase = inject('supabase')

  /**
   *
   * Triggers a Zap.
   * - name of the Zap to trigger (In supabase webhook table)
   * - params: Body for the Zap.
   * - There must be an entry in the webhooks table with the same name as the Zap.
   */
  async function triggerZap(name, params, options = {}) {
    const { notifyFailure, rethrow } = Object.assign(
      { notifyFailure: true, rethrow: false },
      options
    )
    try {
      const { data, error } = await supabase.functions.invoke('webhook-trigger', {
        body: JSON.stringify({
          webhook: name,
          env: import.meta.env.VITE_WEB_ENV,
          params: params
        }),
        region: 'eu-central-1'
      })

      if ((error || !data) && notifyFailure) {
        showNotification({
          message: 'Could not trigger Zap. Please try again.',
          variant: 'danger'
        })
      }
      if ((error || !data) && rethrow) {
        throw new Error('Zap trigger failed')
      }
    } catch (error) {
      if (notifyFailure) {
        showNotification({
          message: 'Could not trigger Zap. Please try again.',
          variant: 'danger'
        })
      }
      if (rethrow) {
        throw error
      }
    }
  }

  return { triggerZap }
}
