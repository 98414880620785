import { defineStore } from 'pinia'

export const useStockTransferStore = defineStore('stockTransfer', {
  state: () => ({
    transfer: {},
    parcel: {}
  }),
  getters: {
    getStockTransfer: (state) => state.transfer,
    getParcel: (state) => state.parcel
  },
  actions: {
    setStockTransfer(object) {
      this.transfer = object
    },
    setParcel(object) {
      this.parcel = object
    },
    updateParcel(object) {
      this.parcel = { ...this.parcel, ...object }
    },
    unsetStockTransfer() {
      this.transfer = {}
    },
    unsetParcel() {
      this.parcel = {}
    }
  }
})
