import { defineStore } from 'pinia'

export const useCustomerStore = defineStore('customer', {
  state: () => ({
    customer: {},
    customerAddress: null,
    customerOrderId: null,
    isCustomerProfileEdit: false,
    guestAccount: {},
    isCustomerSelected: false,
    isOrderOverviewAddress: false,
    orderId: ''
  }),
  getters: {
    getCustomerId: (state) => state.customer.id,
    getCustomerSupabaseId: (state) => state.customer.supabase_userId,
    getCustomerAddress: (state) => state.customerAddress,
    getCustomer: (state) => state.customer,
    getCustomerOrderId: (state) => state.customerOrderId,
    getCurrentCart: (state) => state.customer.currentCart,
    getGuestAccount: (state) => state.guestAccount
  },
  actions: {
    setCustomer(customer) {
      this.customer = customer
    },
    setOrders(orders) {
      this.customer.orders = orders
    },
    setCarts(carts) {
      this.customer.carts = carts
    },
    setCurrentCart(currentCart) {
      this.customer.currentCart = currentCart
    },
    setCustomerAddress(address) {
      this.customerAddress = address
    },
    setCustomerOrderId(id) {
      this.customerOrderId = id
    },
    setGuestAccount(account) {
      this.guestAccount = account
    },
    addCartLineItem(lineItem) {
      this.customer.currentCart.line_items.push(lineItem)
    },
    removeAddressById(id) {
      const indexToRemove = this.customer.customer_addresses.findIndex((a) => a.id === id)
      this.customer.customer_addresses.splice(indexToRemove, 1)
    },
    reset() {
      this.customer = {}
    }
  }
})
