<!-- eslint-disable no-unused-vars -->
<script setup>
import { useDeviceStore } from '@/stores/device'
import { useUiStore } from '@/stores/ui'
import '@shoelace-style/shoelace/dist/components/alert/alert'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/button/button'
import { useI18n } from 'vue-i18n'
import { useOnline } from '@vueuse/core'
import { inject, onMounted, onBeforeMount, ref, watch } from 'vue'
import { RouterView } from 'vue-router'
import Auth from './components/Auth.vue'
import Broadcast from './components/Broadcast.vue'
import DeviceMarketSettingsDialog from './components/Device/DeviceMarketSettingsDialog.vue'
import Header from './components/Layout/Header/Header.vue'
import { useNotification } from '@/composables/useNotification.js'
import { useEmployee } from '@/composables/useEmployee.js'
import { MODE } from '@/constants/device.js'

const supabase = inject('supabase')
const initErrors = inject('initErrors')
const session = ref(true) // to avoid flickering of the auth page.
const deviceStore = useDeviceStore()
const uiStore = useUiStore()
const online = useOnline()
const { t } = useI18n()
const notification = useNotification()
const { employee, addEmployee, buildEmployee, setCurrentEmployee, getEmployeeList } = useEmployee()
const appOutdated = ref(false)

async function setPreLoginSettingsData() {
  const marketSettings = localStorage.getItem('marketSettings')

  if (marketSettings?.length) {
    const settings = JSON.parse(marketSettings)

    let mode = MODE.STORE

    if (['SHAT', 'SHCH', 'SHDE'].includes(settings.warehouseReference)) {
      mode = MODE.SERVICE_HUB
    }

    deviceStore.$patch({
      settings: {
        stockLocationId: settings.stockLocationId,
        warehouse: settings.warehouseReference,
        deviceType: settings.deviceType,
        deviceId: settings.deviceId,
        country: settings.countryCode,
        currency: settings.currencyCode,
        language: settings.language,
        name: settings.warehouse,
        marketId: settings.marketId,
        storeAddressId: settings.storeAddressId,
        serviceHubId: settings.serviceHubId,
        tags: settings.tags,
        mode: mode
      }
    })
  }
  uiStore.settingsLoaded = true
}

async function setUserSettings(user) {
  deviceStore.setStoreUser(user)
  await addEmployee(user.email)

  // throw error if no data - we strongly expect data
  if (!employee.value) {
    session.value = false
    await supabase.auth.signOut()
  }
}

function attachWebSocket() {
  supabase
    .channel('schema-db-changes')
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'employees',
        filter: `id=in.(${deviceStore.settings?.employees?.map((employee) => employee.id).join(',')})`
      },
      (payload) => {
        const sessionUserEmail = deviceStore.user?.email
        if (sessionUserEmail === payload.new.email && !payload.new.auth_user_id) {
          // if the auth ID is not set means the user was deleted
          supabase.auth.signOut()
          session.value = false
        } else if (deviceStore.settings?.currentEmployee.id === payload.new.id) {
          // if is current employee also update the current employee
          setCurrentEmployee(payload.new)
        } else {
          // find employee in the list and update it
          const employeeIndex = deviceStore.settings.employees.findIndex(
            (employee) => employee.id === payload.new.id
          )

          if (employeeIndex !== -1) {
            const updatedEmployee = buildEmployee(payload.new)
            deviceStore.addStoreEmployee(updatedEmployee)
          }
        }
      }
    )
    .subscribe()
}

async function setSessionData(userSession) {
  if (!userSession) {
    supabase.removeAllChannels()
    deviceStore.$reset
    session.value = false
    return
  }

  const { user } = userSession

  // if no user reset session
  if (!user) {
    session.value = false
    notification.showNotification({
      message: t('app.no_active_user'),
      variant: 'danger'
    })
  } else {
    await setUserSettings(user)
    if (employee.value) {
      await setPreLoginSettingsData()
      await getEmployeeList(deviceStore.settings.warehouse)
      attachWebSocket()
    }
  }
}

onBeforeMount(() => {
  const mqStandAlone = '(display-mode: standalone)'
  if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
    uiStore.isHomescreenApp = true
  }
})

onMounted(async () => {
  // get the session
  supabase.auth.getSession().then(async ({ data }) => {
    session.value = data.session

    if (data?.session?.access_token && data?.session?.refresh_token) {
      await supabase.auth.setSession({
        access_token: data.session.access_token,
        refresh_token: data.session.refresh_token
      })
    }
  })

  // https://supabase.com/docs/reference/javascript/auth-onauthstatechange
  supabase.auth.onAuthStateChange(async (event, _session) => {
    if (event === 'SIGNED_OUT') {
      session.value = false
    } else if (event === 'TOKEN_REFRESHED') {
      session.value = _session
    } else if (event === 'USER_UPDATED') {
      session.value = _session
    }
  })
})

// watch session for changes
watch(session, async (newSession) => {
  if (newSession) {
    await setSessionData(newSession)
  }
})

async function getVersionFromEndpoint(endpointUrl) {
  try {
    const response = await fetch(endpointUrl)
    if (!response.ok) {
      throw new Error('Failed to fetch data')
    }
    const data = await response.json()
    return data.version
  } catch (error) {
    console.error('Error fetching data:', error)
    return null
  }
}

async function getVersionEvery120Seconds(endpointUrl) {
  try {
    const version = await getVersionFromEndpoint(endpointUrl)
    if (version !== null) {
      // eslint-disable-next-line no-undef
      if (version !== __APP_VERSION__) {
        appOutdated.value = true
      }
    } else {
      console.log('Failed to get version')
    }
  } catch (error) {
    console.error('Error:', error)
  }
}

function reloadApp() {
  window.location.reload(true)
}

// Replace 'YOUR_ENDPOINT_URL' with your actual endpoint URL
const endpointUrl = '/api/version'

// Call the function every 60 seconds

if (process.env.NODE_ENV === 'production' && session) {
  // Call the function immediately
  getVersionEvery120Seconds(endpointUrl)

  setInterval(() => {
    getVersionEvery120Seconds(endpointUrl)
  }, 120000)
}
</script>

<template>
  <div>
    <template v-if="session && uiStore.settingsLoaded">
      <Suspense>
        <div>
          <Header v-show="uiStore.showAppHeader" />
          <router-view v-slot="{ Component, route }">
            <KeepAlive include="HomeView,StockTransfersView" exclude="ProductView">
              <component :is="Component" v-if="route.name === 'home'" />
              <component :is="Component" v-else-if="route.name === 'stock-transfers'" />
              <transition v-else name="fade" mode="out-in">
                <component :is="Component" />
              </transition>
            </KeepAlive>
          </router-view>
        </div>
      </Suspense>
      <div
        v-if="appOutdated"
        style="position: fixed; width: 420px; top: 8px; right: 24px; z-index: 999"
      >
        <sl-alert variant="warning" open>
          <sl-icon slot="icon" name="arrow-clockwise"></sl-icon>
          <strong>A new Wings-Version is available</strong><br />
          Please complete the current action and than click update to avoid any errors.<br /><br />
          <sl-button variant="primary" size="medium" @click="reloadApp()">Update app now</sl-button>
        </sl-alert>
      </div>
    </template>
    <template v-else>
      <div
        style="
          display: flex;
          gap: 30px;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          height: 100vh;
        "
      >
        <div
          v-for="(error, index) in initErrors"
          :key="index"
          style="width: 524px; margin-top: 20px"
        >
          <sl-alert variant="danger" open>
            <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
            <strong>{{ $t(error.message) }}</strong>
          </sl-alert>
        </div>
        <Auth />
      </div>
    </template>

    <Broadcast />

    <div
      v-if="!online"
      style="position: fixed; width: 420px; top: 8px; right: 24px; z-index: 10000"
    >
      <sl-alert variant="danger" open>
        <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
        <strong>{{ $t('app.network_status_offline') }}</strong
        ><br />
        {{ $t('app.network_status_offline_message') }}
      </sl-alert>
    </div>

    <div
      v-if="
        uiStore.settingsLoaded &&
        (deviceStore.settings.warehouse.length <= 2 ||
          !deviceStore.settings.currentEmployee.eid ||
          deviceStore.settings.currentEmployee.eid === '')
      "
      style="position: fixed; width: 420px; top: 8px; right: 24px; z-index: 10000"
    >
      <sl-alert variant="danger" open>
        <sl-icon slot="icon" name="exclamation-octagon"></sl-icon>
        <strong>{{ $t('app.corruptSettings') }}</strong
        ><br />
        {{ $t('app.corruptSettingsText') }}
      </sl-alert>
    </div>

    <div v-if="session">
      <DeviceMarketSettingsDialog />
    </div>
  </div>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
