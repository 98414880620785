import Typesense from 'typesense'

let _client

function initClient() {
  return new Typesense.Client({
    apiKey: import.meta.env.VITE_TYPESENSE_API_KEY,
    numRetries: 3,
    connectionTimeoutSeconds: 10,
    retryIntervalSeconds: 0.1,
    healthcheckIntervalSeconds: 2,
    nodes: [
      {
        host: import.meta.env.VITE_TYPESENSE_HOST,
        port: 443,
        protocol: 'https'
      }
    ]
  })
}

function client({ unique } = {}) {
  if (unique) {
    return initClient()
  }
  if (!_client) {
    _client = initClient()
  }
  return _client
}

export function useTypesense() {
  return {
    client
  }
}
