<template>
  <div>
    <a
      href="#"
      style="top: 3px; position: relative; padding: 20px 12px 20px 20px; color: white"
      @click="openDrawer()"
    >
      <sl-icon name="list" style="font-size: 26px" />
    </a>
    <sl-drawer
      ref="drawer"
      :label="$t('sidebar.title')"
      class="drawer-menu"
      placement="start"
      style="--size: 360px; color: var(--color--black)"
    >
      <nav>
        <ul>
          <li>
            <RouterLink to="/"
              ><sl-icon name="eyeglasses"></sl-icon>{{ $t('sidebar.products') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink to="/appointments"
              ><sl-icon name="calendar-week"></sl-icon>{{ $t('sidebar.appointments') }}</RouterLink
            >
          </li>
          <li v-access:promotions>
            <RouterLink to="/promotions"
              ><sl-icon name="percent"></sl-icon>{{ $t('sidebar.promotions') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink
              :to="{
                name: 'workbench',
                params: { storeReference: deviceStore.settings.warehouse }
              }"
              ><sl-icon name="controller"></sl-icon>{{ $t('sidebar.workbench') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink
              :to="{
                name: 'incoming-goods',
                params: { storeReference: deviceStore.settings.warehouse }
              }"
              ><sl-icon name="box-arrow-in-down"></sl-icon
              >{{ $t('sidebar.incoming_stock_transfers') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink
              :to="{
                name: 'outgoing-stock-transfers',
                params: { storeReference: deviceStore.settings.warehouse }
              }"
              ><sl-icon name="box-arrow-up"></sl-icon
              >{{ $t('sidebar.outgoing_stock_transfers') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink to="/day-overview"
              ><sl-icon name="cash-coin"></sl-icon>{{ $t('sidebar.day_overview') }}</RouterLink
            >
          </li>
          <li v-access:stockManagement>
            <RouterLink to="/stock-management"
              ><sl-icon name="box-seam"></sl-icon>{{ $t('sidebar.stock_management') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink to="/terminal-management"
              ><sl-icon name="calculator"></sl-icon
              >{{ $t('sidebar.terminal_management') }}</RouterLink
            >
          </li>
          <li>
            <RouterLink to="/reports"
              ><sl-icon name="graph-up"></sl-icon
              >{{ $t('sidebar.reports_and_analytics') }}</RouterLink
            >
          </li>
        </ul>
      </nav>

      <div slot="footer" style="text-align: left">
        <sl-select id="locale" v-model="locale" @sl-change="handleLocalChange">
          <sl-icon slot="prefix" name="translate"></sl-icon>
          <sl-option v-for="(loc, index) in $i18n.availableLocales" :key="index" :value="loc">
            {{ languages[loc] }}
          </sl-option>
        </sl-select>

        <sl-button
          variant="default"
          size="medium"
          style="width: 100%; margin-top: var(--sl-spacing-small)"
          @click="isResetAppSettingsDialogOpen = true"
        >
          <PhArrowsCounterClockwise slot="prefix" :size="20" />
          {{ $t('sidebar.reset_app_settings') }}
        </sl-button>
        <sl-button
          variant="default"
          size="medium"
          style="width: 100%; margin-top: var(--sl-spacing-small)"
          @click="openSettingsDialog()"
        >
          <sl-icon slot="prefix" name="shop"></sl-icon>
          {{ $t('sidebar.store') }}: {{ deviceStore.settings.warehouse }}
        </sl-button>

        <sl-button
          variant="default"
          size="medium"
          style="width: 100%; margin-top: var(--sl-spacing-small)"
          @click="signOut()"
        >
          <sl-icon slot="prefix" name="box-arrow-right"></sl-icon>
          {{ $t('globals.logout') }}
        </sl-button>
        <div style="text-align: left; opacity: 0.4; line-height: 1.3">
          <small> {{ $t('sidebar.app_version') }}: {{ v }}</small
          ><br /><small>{{ $t('sidebar.current_user') }}: {{ deviceStore.user.email }}</small
          ><br />
        </div>
      </div>
    </sl-drawer>

    <sl-dialog
      :open="isResetAppSettingsDialogOpen"
      class="promptDialog"
      :label="$t('menu.dialog.reset_app_settings.title')"
      @sl-request-close="isResetAppSettingsDialogOpen = false"
    >
      <div class="reset-app-wrapper">
        <p class="reset-app-wrapper__title">{{ $t('menu.dialog.reset_app_settings.subtitle') }}</p>
        <div class="reset-app-wrapper__options">
          <sl-switch
            :checked="areDeviceSettingsChecked"
            size="medium"
            @sl-change="areDeviceSettingsChecked = !areDeviceSettingsChecked"
            >{{ $t('menu.dialog.reset_app_settings.toggle.device_settings') }}</sl-switch
          >
          <sl-switch
            :checked="areLastCustomerChecked"
            size="medium"
            @sl-change="areLastCustomerChecked = !areLastCustomerChecked"
            >{{ $t('menu.dialog.reset_app_settings.toggle.last_used_customers') }}</sl-switch
          >
        </div>
      </div>

      <sl-button slot="footer" variant="default" @click="isResetAppSettingsDialogOpen = false">
        {{ $t('globals.cancel') }}</sl-button
      >
      <sl-button
        slot="footer"
        :disabled="isButtonDisabled"
        variant="primary"
        @click="handleAppReset"
        >{{ $t('globals.confirm') }}</sl-button
      >
    </sl-dialog>
  </div>
</template>

<script setup>
import { useDeviceStore } from '@/stores/device'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'

import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/drawer/drawer'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import '@shoelace-style/shoelace/dist/components/option/option'
import '@shoelace-style/shoelace/dist/components/select/select'
import '@shoelace-style/shoelace/dist/components/switch/switch'

import { useNotification } from '@/composables/useNotification.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useUiStore } from '@/stores/ui'
import { PhArrowsCounterClockwise } from '@phosphor-icons/vue'
import { RouterLink } from 'vue-router'

const notification = useNotification()
const supabase = inject('supabase')
const deviceStore = useDeviceStore()
const customerStore = useCustomerStore()
const drawer = ref()
const { locale } = useI18n()
const uiStore = useUiStore()
const isResetAppSettingsDialogOpen = ref(false)
const areDeviceSettingsChecked = ref(false)
const areLastCustomerChecked = ref(false)
const currentCart = customerStore.getCurrentCart

const isButtonDisabled = computed(() => {
  return !(areLastCustomerChecked.value || areDeviceSettingsChecked.value)
})

// eslint-disable-next-line no-undef
const v = __APP_VERSION__
const route = useRoute()
const languages = {
  de: 'Deutsch',
  ch_de: 'Deutsch (Schweiz)',
  en: 'English',
  fr: 'Français'
}

onBeforeMount(() => {
  locale.value =
    deviceStore.settings.country === 'ch' && deviceStore.settings.language === 'de'
      ? 'ch_de'
      : deviceStore.settings.language
})

function openDrawer() {
  drawer.value.show()
}

async function signOut() {
  await supabase.auth.signOut()
}

watch(
  () => route.name,
  () => {
    drawer.value?.hide()
  }
)

function openSettingsDialog() {
  localStorage.removeItem('marketSettings')
  uiStore.showDeviceMarketSettingsDialog = true
  customerStore.customer = {}
  customerStore.setCurrentCart(null)
}

function handleLocalChange(event) {
  locale.value = event.target.value
  updateLanguageInTheLocalStorage(locale.value === 'ch_de' ? 'de' : locale.value)
  deviceStore.settings.language = locale.value === 'ch_de' ? 'de' : locale.value
}

function updateLanguageInTheLocalStorage(lang) {
  const marketSettings = localStorage.getItem('marketSettings')
  const parsedMarketSettings = JSON.parse(marketSettings)

  parsedMarketSettings.language = lang
  localStorage.setItem('marketSettings', JSON.stringify(parsedMarketSettings))
}

async function handleAppReset() {
  if (areLastCustomerChecked.value) {
    localStorage.removeItem('customerList')
    areLastCustomerChecked.value = false
  }

  if (areDeviceSettingsChecked.value) {
    localStorage.removeItem('marketSettings')
  }

  if (currentCart?.value?.id && areDeviceSettingsChecked.value) {
    customerStore.setCurrentCart(null)
  }

  isResetAppSettingsDialogOpen.value = false

  if (!areDeviceSettingsChecked.value) {
    notification.showNotification({ message: 'Your settings have been updated successfully.' })
  }

  if (areDeviceSettingsChecked.value) {
    uiStore.showDeviceMarketSettingsDialog = true
    areDeviceSettingsChecked.value = false
  }
}
</script>

<style lang="postcss" scoped>
sl-drawer ul {
  li + li {
    margin-top: var(--sl-spacing-3x-small);
  }

  sl-icon {
    margin-right: var(--sl-spacing-small);
    width: 18px;
    height: 18px;
  }

  a {
    color: black;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: var(--sl-spacing-x-small) var(--sl-spacing-small);
    border-radius: var(--sl-border-radius-small);

    &:hover {
      background-color: var(--sl-color-neutral-100);
    }

    &.router-link-exact-active,
    &.router-link-active {
      color: var(--color--white);
      background-color: var(--color--black);
    }
  }
}
.reset-app-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__options {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--sl-spacing-x-small);
    width: 335px;
    padding-top: var(--sl-spacing-x-small);
  }
}
</style>
