import { inject, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useDeviceStore } from '@/stores/device'
import { useNotification } from '@/composables/useNotification.js'

export function useEmployee() {
  const env = import.meta.env.VITE_WEB_ENV

  const { t } = useI18n()
  const deviceStore = useDeviceStore()
  const { showNotification } = useNotification()

  const supabase = inject('supabase')
  const Sentry = inject('Sentry')

  const employee = ref({
    id: null,
    pin: null,
    eid: null,
    role: null,
    name: null
  })

  const employeeSelect = `id, pin, eid, role:${env}_role, name, email`

  function buildEmployee(employeePayload) {
    const role = employeePayload.role ? employeePayload.role : employeePayload[`${env}_role`]

    return {
      id: employeePayload.id,
      pin: employeePayload.pin,
      eid: employeePayload.eid,
      role: role,
      name: employeePayload.name,
      email: employeePayload.email
    }
  }

  function addEmployeeToStore(employeePayload) {
    employee.value = buildEmployee(employeePayload)
    deviceStore.addStoreEmployee(employee.value)
  }

  function setCurrentEmployee(employee) {
    employee.value = buildEmployee(employee)
    deviceStore.setCurrentEmployee(employee.value)
  }

  /**
   * Use this to set a new employee.
   * If no current employee is set, the new employee will be set as the current employee.
   */
  async function addEmployee(email) {
    try {
      // set employee context for Sentry
      Sentry.setContext('employee', {
        email: email
      })
      const { data, error } = await supabase
        .from('employees')
        .select(employeeSelect)
        .eq('email', email)
        .single()

      if (error) {
        Sentry.setContext('SupabaseError', {
          error: error
        })

        employee.value = null

        if (error?.message === 'JSON object requested, multiple (or no) rows returned') {
          throw new Error(t('employee.not_found'))
        } else {
          throw new Error('Failed to get employee data')
        }
      }

      if (!data?.role) {
        employee.value = null
        throw new Error(t('employee.not_allowed'))
      }

      addEmployeeToStore(data)

      // If no current employee is set, set the current employee
      if (!deviceStore?.settings?.currentEmployee?.id) {
        deviceStore.setCurrentEmployee(employee)
      }
    } catch (exceptionError) {
      Sentry.captureException(exceptionError)
      employee.value = null
      showNotification({
        message: `Failed to add employee ${email} to the employee list. Please log out and log in again, then select a market.`,
        variant: 'danger'
      })
    }
  }

  async function getEmployeeList(warehouse) {
    const { data } = await supabase
      .from('store_employees')
      .select(`employees(${employeeSelect}))`)
      .eq('store_warehouse', warehouse)
      .eq('app_environment', import.meta.env.VITE_WEB_ENV)

    if (data?.length > 0) {
      data.forEach((e) => {
        deviceStore.addStoreEmployee(e.employees)
      })
    }
  }

  return {
    employee,
    addEmployee,
    addEmployeeToStore,
    setCurrentEmployee,
    getEmployeeList,
    buildEmployee
  }
}
