import { object as yupObject } from 'yup'
export class MetaDataError extends Error {
  constructor(message) {
    super(message)
    this.name = 'MetaDataError'
  }
}

export class MetaDataYup {
  constructor(object) {
    this.object = object
    this.schema = this._shape()
  }

  _shape() {
    // use noUnknown() to prevent additional keys from being added to the object
    return yupObject().shape(this.object).noUnknown()
  }

  async validate(objectToUpdate) {
    const metadataToUpdate = objectToUpdate || {}
    try {
      await this.schema.validate(metadataToUpdate, { strict: false })
    } catch (error) {
      throw new MetaDataError(error.message)
    }
  }
}
