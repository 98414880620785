function setCustomerInTheLocalStorage({ id, firstName, lastName, email, restrictions }) {
  if (!id) {
    return
  }

  const newCustomer = {
    id,
    firstName,
    lastName,
    email,
    restrictions
  }

  let storedCustomerList = JSON.parse(localStorage.getItem('customerList')) || []

  const customerInList = storedCustomerList.find((customer) => customer.id === newCustomer.id)

  if (customerInList) {
    return
  }

  storedCustomerList.unshift(newCustomer)

  if (storedCustomerList.length > 3) {
    storedCustomerList.pop()
  }
  localStorage.setItem('customerList', JSON.stringify(storedCustomerList))
}

function getCustomersFromLocalStorage() {
  return JSON.parse(localStorage.getItem('customerList')) || []
}

function removeCustomerFromLocalStorageById(id) {
  const customers = getCustomersFromLocalStorage()
  const customerIndex = customers.findIndex((c) => c.id === id)
  if (customerIndex !== -1) {
    customers.splice(customerIndex, 1)
    localStorage.setItem('customerList', JSON.stringify(customers))
  }
}

function updateCustomerRestriction(customerEmail, restriction) {
  const customers = getCustomersFromLocalStorage()
  const index = customers.findIndex((item) => item.email === customerEmail)
  if (index !== -1) {
    customers[index].restrictions = restriction
    localStorage.setItem('customerList', JSON.stringify(customers))
  }
}

export {
  setCustomerInTheLocalStorage,
  getCustomersFromLocalStorage,
  removeCustomerFromLocalStorageById,
  updateCustomerRestriction
}
