<template>
  <div style="position: relative">
    <div class="basket-wrapper">
      <template v-if="!cartLineItems?.length">
        <sl-button slot="trigger" variant="text" size="medium" class="cartButton">
          <PhShoppingBag color="white" :size="24" />
        </sl-button>
      </template>

      <template v-else>
        <sl-button
          slot="trigger"
          variant="text"
          size="medium"
          class="cartButton"
          @click="uiStore.showCart = true"
        >
          <PhShoppingBag color="white" :size="24" />
          <sl-badge variant="primary" pill>{{ cartLineItems?.length }}</sl-badge>
        </sl-button>
      </template>
    </div>
  </div>
</template>

<script setup>
import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/drawer/drawer'
import '@shoelace-style/shoelace/dist/components/badge/badge'
import { PhShoppingBag } from '@phosphor-icons/vue'
import { useUiStore } from '@/stores/ui.js'
import { useCart } from '@/composables/useCart.js'

const uiStore = useUiStore()
const { cartLineItems } = useCart()
</script>

<style>
.cartButton sl-badge {
  left: 12px;
  top: 10px;

  &::part(base) {
    background-color: white;
    color: black;
    padding: 2px 5px;
  }
}

.basket-wrapper {
  sl-button {
    &::part(label) {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: var(--sl-spacing-x-small);
      padding-left: var(--sl-spacing-large);
    }
  }
}
</style>
