import { defineStore } from 'pinia'

export const useFulfillmentStore = defineStore('fulfillment', {
  state: () => ({
    fulfillment: {},
    fulfillmentId: null
  }),
  getters: {
    getFulfillmentId: (state) => state.fulfillmentId,
    getFulfillment: (state) => state.fulfillment
  },
  actions: {
    setFulfillment(fulfillment) {
      this.fulfillment = fulfillment
    },
    setFulfillmentId(id) {
      this.fulfillmentId = id
    },
    reset() {
      this.fulfillment = {}
    }
  }
})
