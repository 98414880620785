import { FunctionsHttpError } from '@supabase/supabase-js'

// https://github.com/supabase/functions-js/issues/55#issuecomment-2068191085
export async function fetchEdgeFunctionError(error) {
  if (error && error instanceof FunctionsHttpError) {
    return error.context.json()
  } else {
    return error
  }
}
