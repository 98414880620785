<template>
  <div>
    <sl-dropdown v-if="!showEmployeeListDialog" placement="bottom-start" distance="2">
      <sl-button slot="trigger" class="employeeSwitch" caret>
        {{ deviceStore.settings.currentEmployee.name }}
      </sl-button>
      <sl-menu>
        <sl-menu-label>Current Employee</sl-menu-label>
        <sl-menu-item>
          <div class="listItem">
            <b>{{ deviceStore.settings.currentEmployee.name }}</b
            ><br />
            <div class="text-color-neutral">{{ deviceStore.settings.currentEmployee.email }}</div>
          </div>
        </sl-menu-item>
        <template v-if="deviceStore.settings.deviceType !== 'Personal'">
          <sl-divider></sl-divider>
          <sl-menu-label>Store Employees</sl-menu-label>
          <sl-menu-item
            v-for="employee in settings.employees"
            :key="employee.email"
            :disabled="employee.email === deviceStore.settings.currentEmployee.email"
          >
            <div class="listItemLayout">
              <div class="listItem" @click="changeCurrentEmployee(employee)">
                {{ employee.name }}<br />
                <div class="text-color-neutral">{{ employee.email }}</div>
              </div>
              <sl-icon-button
                v-if="
                  employee.email !== deviceStore.settings.currentEmployee.email &&
                  employee.email !== deviceStore.user.email
                "
                name="x-lg"
                label="Remove"
                @click="removeEmployeFromStore(employee)"
              ></sl-icon-button>
            </div>
          </sl-menu-item>
          <sl-divider v-if="settings.employees.length > 0"></sl-divider>
          <sl-menu-item
            @click=";(showEmployeeLoginDialog = true), (showEmployeeSwitchDialog = false)"
          >
            <sl-icon slot="suffix" name="box-arrow-in-left"></sl-icon>
            Sign-in into {{ deviceStore.settings.warehouse }}
          </sl-menu-item>
        </template>
      </sl-menu>
    </sl-dropdown>

    <sl-dialog
      :open="showEmployeeListDialog && !uiStore.showDeviceMarketSettingsDialog"
      no-header
      no-footer
      @sl-request-close="handleEmployeeListClose"
    >
      <h2 style="padding: 20px 0">{{ $t('employeeLogin.selectEmployee') }}</h2>
      <div v-for="employee in settings.employees" :key="employee.email">
        <div class="listItem listItem--spaced" @click="changeCurrentEmployee(employee)">
          {{ employee.name }}<br />
          <div class="text-color-neutral">{{ employee.email }}</div>
        </div>
      </div>
      <div
        class="listItem listItem--spaced text-right"
        style="padding-top: var(--sl-spacing-medium)"
      >
        <sl-button @click=";(showEmployeeLoginDialog = true), (showEmployeeSwitchDialog = false)">
          <sl-icon slot="suffix" name="box-arrow-in-left"></sl-icon>
          Sign-in into {{ deviceStore.settings.warehouse }}
        </sl-button>
      </div>
    </sl-dialog>

    <sl-dialog
      ref="employeeLoginDialog"
      no-footer
      :label="
        showEmployeeLoginDialog
          ? $t('employeeLogin.dialogLoginTitle')
          : $t('employeeLogin.dialogSwitchEmployeeTitle')
      "
      style="--width: 400px"
    >
      <EmployeeLoginDialog
        v-if="showEmployeeLoginDialog || showEmployeeSwitchDialog"
        :mode="showEmployeeSwitchDialog ? 'switch' : 'login'"
        @closeLoginDialog="closeDialog()"
      />
    </sl-dialog>
  </div>
</template>

<script setup>
import { ref, inject, defineAsyncComponent, watch, onMounted } from 'vue'
import { useDeviceStore } from '@/stores/device'
import { useUiStore } from '@/stores/ui'
import { storeToRefs } from 'pinia'

const supabase = inject('supabase')
const EmployeeLoginDialog = defineAsyncComponent(
  () => import('@/components/Employee/EmployeeLoginDialog.vue')
)

import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/icon-button/icon-button'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label'
import '@shoelace-style/shoelace/dist/components/divider/divider'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import { useNotification } from '@/composables/useNotification.js'

defineEmits(['closeLoginDialog'])

const deviceStore = useDeviceStore()
const uiStore = useUiStore()
const notification = useNotification()
const { settings } = storeToRefs(deviceStore)
const employeeLoginDialog = ref()
const showEmployeeLoginDialog = ref(false)
const showEmployeeSwitchDialog = ref(false)
const showEmployeeListDialog = ref(deviceStore.settings.deviceType !== 'Personal' ? true : false)

watch([showEmployeeLoginDialog, showEmployeeSwitchDialog], ([newLD, newSD]) => {
  if (newLD || newSD) {
    employeeLoginDialog.value.show()
  }
})

onMounted(() => {
  setTimeout(() => {
    employeeLoginDialog.value?.addEventListener('sl-hide', (event) => {
      if (event.target !== employeeLoginDialog.value) return

      event.stopPropagation()
      showEmployeeLoginDialog.value = false
      showEmployeeSwitchDialog.value = false
    })
  }, 200)

  document.addEventListener('visibilitychange', function () {
    if (
      document.visibilityState === 'visible' &&
      !showEmployeeListDialog.value &&
      deviceStore.settings.deviceType !== 'Personal'
    ) {
      showEmployeeListDialog.value = true
    }
  })
})

function closeDialog() {
  showEmployeeListDialog.value = false
  showEmployeeLoginDialog.value = false
  showEmployeeSwitchDialog.value = false
  employeeLoginDialog.value.hide()
}

function handleEmployeeListClose(event) {
  event.stopPropagation()
  if (['overlay', 'keyboard'].includes(event.detail.source)) {
    event.preventDefault()
  }
}

function changeCurrentEmployee() {
  showEmployeeSwitchDialog.value = true
}

async function removeEmployeFromStore(employee) {
  const { error } = await supabase
    .from('store_employees')
    .delete()
    .eq('employee_id', employee.id)
    .eq('store_warehouse', deviceStore.settings.warehouse)
    .eq('app_environment', import.meta.env.VITE_WEB_ENV)

  if (!error) {
    deviceStore.removeStoreEmployee(employee)
    notification.showNotification({
      message: `${employee.name ? employee.name : 'Employee'} has been removed from the store.`,
      icon: 'person-dash'
    })
  }
}
</script>

<style lang="postcss" scoped>
sl-menu-item {
  &::part(base) {
    font-size: var(--sl-font-size-small);
  }
}

.listItemLayout {
  display: flex;
  justify-content: space-between;
  gap: 24px;
}

.listItem {
  line-height: var(--sl-line-height-dense);
  min-width: 180px;
  font-size: var(--sl-font-size-small);
  cursor: pointer;

  &--spaced {
    border-top: 1px solid var(--sl-color-neutral-300);
    padding: var(--sl-spacing-x-small) 0;
  }
}
</style>
