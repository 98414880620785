<script setup>
import { ref, inject, onBeforeMount } from 'vue'

import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/alert/alert'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import FormInput from '@/components/Form/FormInput/FormInput.vue'
import { useNotification } from '@/composables/useNotification.js'

const supabase = inject('supabase')
const notification = useNotification()
const showInstallPromt = ref(true)
const isTestLoginFormVisible = ref(false)
const isProductionEnv = ref(false)
const testLoginForm = ref({
  email: '',
  password: ''
})

const mqStandAlone = '(display-mode: standalone)'
if (navigator.standalone || window.matchMedia(mqStandAlone).matches) {
  showInstallPromt.value = false
}

onBeforeMount(() => {
  if (window.location.hostname === 'wings.shopviu.com') {
    isProductionEnv.value = true
  }
})

async function signInWithSSO() {
  const { data, error } = await supabase.auth.signInWithSSO({
    domain: 'shopviu.com',
    options: {
      redirectTo: window.location.origin
    }
  })

  if (data?.url) {
    // redirect the user to the identity provider's authentication flow - we use our custom SAMl provider added via Google Workspace
    window.location.href = data.url
  } else if (error) {
    notification.showNotification({
      message: error,
      variant: 'danger',
      icon: 'person-x'
    })
  }
}

async function handleLogin() {
  const { error } = await supabase.auth.signInWithPassword({
    email: testLoginForm.value.email,
    password: testLoginForm.value.password
  })

  if (error) {
    notification.showNotification({
      message: error,
      variant: 'danger',
      icon: 'person-x'
    })
  }
}
</script>

<template>
  <div>
    <div v-if="!isTestLoginFormVisible" class="login">
      <sl-alert v-if="showInstallPromt" variant="neutral" open>
        <sl-icon slot="icon" name="gear"></sl-icon>
        <strong>Install the App on the Homescreen</strong><br />
        Please install this App on the Homescreen of your device to use it.
      </sl-alert>
      <br />
      <sl-button href="#" @click.prevent="signInWithSSO()">
        <sl-icon slot="prefix" name="google"></sl-icon>
        Sign in to Wings</sl-button
      >
      <sl-button
        v-if="!isProductionEnv"
        data-testid="show-test-login-form-button"
        class="show-test-login-button"
        @click="isTestLoginFormVisible = true"
      >
        Show test login form</sl-button
      >
    </div>
    <div v-else class="test-login-form">
      <h2>Test Login Form</h2>
      <div class="test-login-form__content">
        <form-input v-model="testLoginForm.email" data-testid="email" />
        <form-input v-model="testLoginForm.password" data-testid="password" />
      </div>
      <sl-button
        :disabled="!testLoginForm.email.length || !testLoginForm.password.length"
        data-testid="login-button"
        class="test-login-form__login-button"
        @click="handleLogin"
      >
        Login</sl-button
      >
    </div>
  </div>
</template>

<style scoped lang="postcss">
.login,
.test-login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.test-login-form {
  &__content {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin-top: var(--sl-spacing-small);
    gap: var(--sl-spacing-x-small);
  }
  &__login-button {
    margin-top: var(--sl-spacing-small);
  }
}
.show-test-login-button {
  margin-top: var(--sl-spacing-small);
}
</style>
