import { COUNTRIES } from '@/constants/countries.js'

export function getCountryName(countryCode) {
  if (countryCode) {
    const country = COUNTRIES.find((country) => country.value === countryCode)
    if (country) {
      return country.text
    }
    return ''
  }

  return ''
}
