import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useDeviceStore } from '@/stores/device.js'
import { inject, computed } from 'vue'
import { useNotification } from '@/composables/useNotification.js'
import { getErrorMessage } from '@/utils/error.js'
import { useCart } from '@/composables/useCart.js'
export function useShipment() {
  const cartStore = useCartStore()
  const customerStore = useCustomerStore()
  const deviceStore = useDeviceStore()
  const { cartUpdate } = useCart()
  const { showNotification } = useNotification()
  const cl = inject('cl')

  async function setShippingMethod(shippingMethodReference, cartId) {
    await cl.orders.update({
      id: cartId,
      metadata: {
        shipping_method: shippingMethodReference
      }
    })
    cartStore.hasValidShippingMethod = true
  }

  async function setClShippingMethod(shippingMethodReference, cartId) {
    try {
      const order = await cl.orders.retrieve(cartId, {
        include: ['shipments.available_shipping_methods']
      })
      const shippingMethod = order?.shipments[0]?.available_shipping_methods.find(
        (shippingMethod) => shippingMethod.reference === shippingMethodReference
      )

      if (!shippingMethod) {
        cartStore.hasValidShippingMethod = false
        return
      }

      cartStore.hasValidShippingMethod = true

      const data = {
        id: order.shipments[0].id,
        shipping_method: cl.shipping_methods.relationship(shippingMethod.id)
      }

      await cl.shipments.update(data)
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function setBillingAddress(billingAddressId) {
    try {
      await cartUpdate({
        id: customerStore.customer.currentCart.id,
        _billing_address_clone_id: billingAddressId
      })
      cartStore
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  async function setShippingAddressAndMethod(shippingMethodReference, shippingAddressId) {
    const storeAddressId = computed(() => deviceStore.getStoreAddressId)

    if (!shippingAddressId) {
      showNotification({
        message: 'setShippingAddressAndMethod: shippingAddressId is required',
        variant: 'danger'
      })
    }

    try {
      const cartMeta = customerStore.customer.currentCart.metadata

      if (shippingMethodReference === 'SHIPPING') {
        delete cartMeta.pickup_location
      }

      const data = {
        id: customerStore.customer.currentCart.id,
        ...(storeAddressId.value !== shippingAddressId && {
          _shipping_address_clone_id: shippingAddressId
        }),
        ...(storeAddressId.value === shippingAddressId && {
          shipping_address: cl.addresses.relationship(shippingAddressId)
        }),
        metadata: {
          ...cartMeta,
          ...(shippingMethodReference === 'PICKUP' && {
            pickup_location: cartStore.pickupLocation
          }),
          delivery_method: shippingMethodReference
        }
      }

      await cartUpdate(data)
    } catch (error) {
      showNotification({
        message: getErrorMessage(error),
        variant: 'danger'
      })
    }
  }

  return {
    setShippingMethod,
    setClShippingMethod,
    //setShippingAddress,
    setBillingAddress,
    setShippingAddressAndMethod
  }
}
