<template>
  <div>
    <div class="clientSelector" style="z-index: 699">
      <CustomerSearch @openAddNewCustomer="handleCustomerDialogDisplay()" />
      <sl-dropdown
        v-if="customer.id"
        ref="customerMenuDropdown"
        placement="bottom-end"
        distance="2"
        style="margin-left: var(--sl-spacing-x-small)"
      >
        <sl-button slot="trigger" data-testid="menu-customer-dropdown" caret>
          <div
            class="dropdown-button-content"
            :class="[
              {
                'dropdown-button-content--warning':
                  customer.metadata?.restrictions === CUSTOMER_RESTRICTION.PREPAYMENT
              },
              {
                'dropdown-button-content--danger': [
                  CUSTOMER_RESTRICTION.BANNED,
                  CUSTOMER_RESTRICTION.BLOCKED
                ].includes(customer.metadata?.restrictions)
              }
            ]"
          >
            <template
              v-if="
                [CUSTOMER_RESTRICTION.BANNED, CUSTOMER_RESTRICTION.BLOCKED].includes(
                  customer.metadata?.restrictions
                )
              "
            >
              <PhWarning :size="18" />
            </template>

            <template v-if="customer.metadata?.restrictions === CUSTOMER_RESTRICTION.PREPAYMENT">
              <PhHandCoins :size="18" />
            </template>

            <template v-if="customer.metadata?.first_name">
              {{ customer.metadata?.first_name }} {{ customer.metadata?.last_name }}
            </template>
            <template v-else>{{ customer.email }}</template>
          </div>
        </sl-button>
        <sl-menu class="customer-actions-menu">
          <sl-menu-item @click="handleOpenDrawer('profile')">
            <div class="customer-actions-menu__item" data-testid="menu-customer-profile-option">
              <PhListBullets class="customer-actions-menu__item-icon" weight="regular" :size="20" />
              <p class="customer-actions-menu__item-label">
                {{ $t('customer.menu.customer_profile') }}
              </p>
            </div>
          </sl-menu-item>
          <sl-menu-item @click="handleOpenDrawer('orders')">
            <div class="customer-actions-menu__item">
              <PhPackage class="customer-actions-menu__item-icon" weight="regular" :size="20" />
              <p class="customer-actions-menu__item-label">{{ $t('customer.menu.orders') }}</p>
            </div>
            <sl-badge
              v-if="customer.total_orders_count"
              class="customer-actions-menu__item-badge"
              >{{ customer.total_orders_count }}</sl-badge
            >
          </sl-menu-item>
          <sl-menu-item @click="handleOpenDrawer('prescriptions')">
            <div class="customer-actions-menu__item">
              <PhEyeglasses class="customer-actions-menu__item-icon" weight="regular" :size="20" />
              <p class="customer-actions-menu__item-label">
                {{ $t('customer.menu.prescriptions') }}
              </p>
            </div>
          </sl-menu-item>
          <sl-menu-item @click="handleOpenDrawer('vouchers')">
            <div class="customer-actions-menu__item">
              <PhCards class="customer-actions-menu__item-icon" weight="regular" :size="20" />
              <p class="customer-actions-menu__item-label">
                {{ $t('sidebar.vouchersAndCoupons') }}
              </p>
            </div>
          </sl-menu-item>
          <template
            v-if="
              (customer.metadata?.restrictions && customer.metadata?.restrictions !== 'null') ||
              customer.metadata?.notes
            "
          >
            <sl-divider></sl-divider>
            <sl-menu-item class="dense">
              <sl-icon slot="prefix" name="exclamation-triangle"></sl-icon>
              <div class="dense" style="font-size: var(--sl-font-size-small)">
                <b
                  v-if="customer.metadata?.restrictions === CUSTOMER_RESTRICTION.BLOCKED"
                  style="color: var(--sl-color-danger-600)"
                  >{{ $t('cart.alert.customerResctrictionBlocked') }}</b
                >
                <b
                  v-if="customer.metadata?.restrictions === CUSTOMER_RESTRICTION.BANNED"
                  style="color: var(--sl-color-danger-600)"
                  >{{ $t('cart.alert.customerResctrictionBanned') }}</b
                >
                <b
                  v-if="customer.metadata?.restrictions === CUSTOMER_RESTRICTION.PREPAYMENT"
                  style="color: var(--sl-color-warning-500)"
                  >{{ $t('cart.alert.customerRestrictionPrePayment') }}</b
                >
                <div v-if="customer.metadata?.notes">{{ customer.metadata.notes }}</div>
              </div>
            </sl-menu-item>
          </template>
          <sl-divider class="customer-actions-menu__divider" />
          <sl-menu-item @click="handleCustomerSignOut">
            <div class="customer-actions-menu__item">
              <PhSignOut class="customer-actions-menu__item-icon" weight="regular" :size="20" />
              <p class="customer-actions-menu__item-sign-out-label">
                {{ $t('globals.sign_out') }}
              </p>
            </div>
          </sl-menu-item>
        </sl-menu>
      </sl-dropdown>
    </div>
    <section class="drawers">
      <CustomerProfileComp
        v-if="currentCustomerDrawer === 'profile'"
        @openEditCustomerDialog="handleCustomerDialogDisplay()"
      />
      <CustomerVouchersComp v-if="currentCustomerDrawer === 'vouchers'" />
      <CustomerPrescriptionsComp v-if="currentCustomerDrawer === 'prescriptions'" />
      <CustomerOrdersComp v-if="currentCustomerDrawer === 'orders'" />
      <CustomerOrderDetailsComp v-if="uiStore.showCustomerOrderDetails" />
      <FulfillmentDetails v-if="fulfillmentStore.fulfillmentId" />
      <IncomingGoodsParcelDrawer v-if="uiStore.showIncomingGoodsParcelDrawer" />
    </section>

    <section class="dialogs">
      <CustomerAddressAddOrEditDialog
        v-if="uiStore.showCustomerAddressCreationEditDialog"
        :is-delete-address-button-visible="currentCustomerDrawer === 'profile'"
      />
      <CustomerAddOrEditDialogDialogComp v-if="uiStore.showCustomerCreationEditDialog" />
      <CustomerAnonymiseDialogComp v-if="uiStore.showAnonymiseCustomerDialog" />
      <CustomerAddPrescriptionComp
        v-if="uiStore.showAddPrescriptionDialog"
        @refetchPrescriptions="$emit('refetchPrescriptions')"
      />
      <CartDialogComp v-if="uiStore.showCart" />

      <CartChooseAddressDrawer v-if="uiStore.showCartChooseAddressDrawer" />
      <StockTransferDetailDialog v-if="uiStore.showStockTransferDetails" />
    </section>
  </div>
</template>

<script setup>
import { useCartStore } from '@/stores/cart.js'
import { useCustomerStore } from '@/stores/customer.js'
import { useFulfillmentStore } from '@/stores/fulfillment.js'
import { useUiStore } from '@/stores/ui.js'
import { storeToRefs } from 'pinia'
import { computed, defineAsyncComponent, ref } from 'vue'
import CustomerSearch from '../CustomerSearch/CustomerSearch.vue'

import CartChooseAddressDrawer from '@/components/Cart/CartChooseAddressDrawer/CartChooseAddressDrawer.vue'
import { CUSTOMER_RESTRICTION } from '@/constants/customer.js'
import {
  PhCards,
  PhEyeglasses,
  PhHandCoins,
  PhListBullets,
  PhPackage,
  PhSignOut,
  PhWarning
} from '@phosphor-icons/vue'
import '@shoelace-style/shoelace/dist/components/badge/badge'
import '@shoelace-style/shoelace/dist/components/button/button'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/divider/divider'
import '@shoelace-style/shoelace/dist/components/dropdown/dropdown'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import '@shoelace-style/shoelace/dist/components/menu-item/menu-item'
import '@shoelace-style/shoelace/dist/components/menu-label/menu-label'
import '@shoelace-style/shoelace/dist/components/menu/menu'
import { useRouter } from 'vue-router'

const CustomerAddOrEditDialogDialogComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerAddOrEditDialog/CustomerAddOrEditDialog.vue')
)
const CustomerAddPrescriptionComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerPrescriptions/CustomerAddPrescriptionDialog.vue')
)
const CustomerProfileComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerProfile/CustomerProfile.vue')
)
const CustomerVouchersComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerVouchers/CustomerVouchers.vue')
)
const CustomerPrescriptionsComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerPrescriptions/CustomerPrescriptions.vue')
)
const CustomerOrdersComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerOrders/CustomerOrders.vue')
)
const CustomerOrderDetailsComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerOrderDetails/CustomerOrderDetails.vue')
)
const CustomerAnonymiseDialogComp = defineAsyncComponent(
  () => import('@/components/Customer/CustomerAnonymiseDialog/CustomerAnonymiseDialog.vue')
)
const CartDialogComp = defineAsyncComponent(
  () => import('@/components/Cart/CartDialog/CartDialog.vue')
)

const CustomerAddressAddOrEditDialog = defineAsyncComponent(
  () =>
    import(
      '@/components/Customer/CustomerAddressAddOrEditDialog/CustomerAddressAddOrEditDialog.vue'
    )
)

const StockTransferDetailDialog = defineAsyncComponent(
  () => import('@/components/StockTransfer/StockTransferDetailDialog/StockTransferDetailDialog.vue')
)

const IncomingGoodsParcelDrawer = defineAsyncComponent(
  () => import('@/components/IncomingGoods/IncomingGoodsParcelDrawer/IncomingGoodsParcelDrawer.vue')
)

const FulfillmentDetails = defineAsyncComponent(
  () => import('@/components/Fulfillment/FulfillmentDetails/FulfillmentDetails.vue')
)

const router = useRouter()
const customerStore = useCustomerStore()
const cartStore = useCartStore()
const uiStore = useUiStore()
const fulfillmentStore = useFulfillmentStore()
const customer = computed(() => customerStore.getCustomer)
const { currentCustomerDrawer } = storeToRefs(uiStore)
const customerMenuDropdown = ref()

function handleCustomerDialogDisplay() {
  if (customerMenuDropdown.value?.open) {
    customerMenuDropdown.value.open = !customerMenuDropdown.value?.open
  }

  customerStore.isCustomerProfileEdit = false
  uiStore.showCustomerCreationEditDialog = true
}

function handleCustomerSignOut() {
  cartStore.$reset()
  customerStore.reset()
  uiStore.showCart = false
  router.push({ name: 'home' })
}

function handleOpenDrawer(name) {
  if (customerMenuDropdown.value?.open) {
    customerMenuDropdown.value.open = !customerMenuDropdown.value?.open
  }
  uiStore.$patch({ currentCustomerDrawer: name })
}
</script>
<style lang="postcss" scoped src="./CustomerMenu.css" />
