import { defineStore } from 'pinia'
import { CURRENCY_CODE } from '@/constants/payment.js'

export const useDeviceStore = defineStore('config', {
  state: () => ({
    user: {},
    settings: {
      stockLocationId: '',
      marketId: 'vlzmJhGEag',
      warehouse: 'ZRH1',
      country: 'ch',
      currency: CURRENCY_CODE.EUR,
      deviceId: 'ipad123-zrh1',
      language: 'de',
      name: 'Zürich Grüngasse',
      storeAddressId: '',
      currentEmployee: {},
      employees: [],
      tags: [],
      mode: 'STORE' // STORE | SERVICE_HUB
    }
  }),
  getters: {
    getUser: (state) => state.user,
    getStoreCountryCode: (state) => state.settings.country,
    getStoreAddressId: (state) => state.settings.storeAddressId,
    getMarketId: (state) => state.settings.marketId,
    getTags: (state) => state.settings.tags
  },
  actions: {
    setStoreUser(user) {
      this.user = user
    },
    setStoreEmployees(employees) {
      this.settings.employees = employees
    },
    addStoreEmployee(employee) {
      this.settings.employees.push(employee)
      this.settings.employees = [
        ...new Map(this.settings.employees.map((item) => [item['id'], item])).values()
      ]
    },
    removeStoreEmployee(employee) {
      this.settings.employees = this.settings.employees.filter((item) => item.id !== employee.id)
    },
    async getCurrentEmployee() {
      if (!this.settings.currentEmployee?.id) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        return this.getCurrentEmployee()
      }
      return this.settings.currentEmployee
    },
    setCurrentEmployee(employee) {
      this.settings.currentEmployee = employee
    }
  }
})
