<template>
  <header class="header">
    <div style="width: 300px; display: flex; align-items: center">
      <Menu />
      <EmployeeSelector />
    </div>
    <div class="viu-logo" @click="handleCloseDialogs">
      <RouterLink to="/" style="align-self: center"
        ><img src="../../../assets/logo.png"
      /></RouterLink>
      <div class="env" :class="`env--${env}`">{{ env }}</div>
    </div>
    <div style="width: 340px; display: flex; justify-content: flex-end">
      <template v-if="cl">
        <CustomerMenu />
        <Basket />
      </template>
    </div>
  </header>
</template>

<script setup>
import CustomerMenu from '@/components/Customer/CustomerMenu/CustomerMenu.vue'
import { useUiStore } from '@/stores/ui.js'
import { inject } from 'vue'
import EmployeeSelector from '../../Employee/EmployeeSelector.vue'
import Basket from './Basket.vue'
import Menu from './Menu.vue'

const cl = inject('cl')
const uiStore = useUiStore()
const env = import.meta.env.VITE_WEB_ENV

function handleCloseDialogs() {
  uiStore.showCart = false
}
</script>

<style lang="postcss" scoped>
.header {
  position: fixed;
  width: 100vw;
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--sl-spacing-large) 0 0;
  height: 68px;
  z-index: 10;

  img {
    width: 120px;
    padding: 24px;
  }
  .viu-logo {
    position: relative;
    display: flex;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
}

.env {
  position: absolute;
  top: 36px;
  right: 0;
  border: 2px solid #000;
  font-size: 10px;
  line-height: 1;
  padding: 3px 5px;
  border-radius: 4px;

  &--live {
    background-color: red;
  }

  &--test {
    background-color: yellow;
  }
}
</style>
