import { defineStore } from 'pinia'
//import { SHIPPING_METHOD } from '@/constants/shipping.js'

export const useCartStore = defineStore('cart', {
  state: () => ({
    shippingMethod: '',
    pickupLocation: '',
    hasValidShippingMethod: true,
    hasValidShippingAddress: true,
    isBillingAddress: false,
    isActiveCartAddress: false,
    cityVouchers: [],
    voucher: {},
    customerSetOnCart: false,
    shippingAddressSetOnCart: false,
    marketSetOnCart: false,
    stockLocationOnCart: false,
    itemStatusColours: [],
    shippingAddress: {},
    billingAddress: {},
    hasInvalidStockItems: false,
    awaitCaseSelectionItems: []
  }),
  getters: {
    getShippingMethod: (state) => state.shippingMethod,
    getPickupLocation: (state) => state.pickupLocation,
    getCityVouchers: (state) => state.cityVouchers,
    getVoucher: (state) => state.voucher,
    getShippingAddress: (state) => state.shippingAddress,
    getBillingAddress: (state) => state.billingAddress
  },
  actions: {
    setShippingAddress(address) {
      this.shippingAddress = address
    },
    setBillingAddress(address) {
      this.billingAddress = address
    }
  }
})
