import { defineStore } from 'pinia'

/*function calculateHauptSchnitt(prescription) {
  let hauptSchnitt = Math.abs(parseFloat(prescription.spherical))

  if (prescription.cylindrical) {
    hauptSchnitt += Math.abs(parseFloat(prescription.cylindrical))
  }

  return hauptSchnitt
}*/

export const useConfiguratorStore = defineStore('configurator', {
  state: () => {
    return {
      variant: null,
      alternativeVariants: false,
      availableCases: [],
      configuratorSteps: {},
      currentColourClassification: '',
      selectedGlassType: null,
      glassTypes: [],
      config: {},
      currentLensSupplier: null,
      lensSuppliersToApply: null,
      lensSuppliers: null,
      lensSupplierChangeReason: null,
      currentLensSupplierFound: false,
      currentSupplierMessage: null,
      fetchingLensSupplier: false,
      configuratorOptions: [],
      configuratorOptions3D: [],
      selectedOptions: {
        1: [],
        2: [],
        3: [],
        4: [],
        5: []
      },
      hiddenSections: {
        extras: false,
        glassConfiguration: false,
        glassRefinement: false,
        viewRange: false,
        workSpaceGlasses: false,
        glassIndexes: false,
        complexGlasses: false,
        glassColour: false
      },
      disabledSections: {
        prescription: false
      },

      glassMaterials: [],
      glassIndexes: [],
      glassColours: [],
      glassRefinements: [],
      giftcardType: 'electronic',
      giftcardAmount: null,
      giftcardCode: '',
      tooltips: [],
      insuranceTermsAccepted: false,
      isSparePart: false,
      sparePartId: null,
      sparePartType: null,
      prescriptionIsValid: false,
      prescriptionCorrection: {
        right: null,
        left: null,
        note: null
      },
      customMadeFrameComplete: false,
      customMadeFrameDesign: false,
      customMadeFrameTemple: false,
      configurationToApply: null,
      itemToReplace: null,
      setSparePartOnInit: false,
      optionsToAddOnInit: [],
      prescriptionToApply: null,
      setGlassTypeOnInit: null,
      setSelectedGlassTypeOnInit: null,
      runningLensRequest: 0
    }
  },
  getters: {
    getFlatSelectedOptions(state) {
      return Object.values(state.selectedOptions)
        .flat()
        .map((o) => {
          return o
        })
    },
    getFlatOptions(state) {
      return state.configuratorOptions.flat(2)
    },
    getAvailableRefinements(state) {
      if (!state.variant || state.variant.type === 'accessory') {
        return
      }

      if (state.hiddenSections.glassRefinement) {
        return []
      }

      let refinements = state.glassRefinements
      let itemsToRemove = []

      /*
      If bifokale gläser
      If type is Arbeitsplatzgläser Standard A OR Arbeitsplatzgläser Standard B
        - hide Premium Blaulichtfilter
        - hide Blaulichtfilter
      */
      if (
        (state.config?.prescription && state.config?.prescription?.type === 'LENS20') ||
        state.config?.glass?.lens === 'LENS13' ||
        state.config?.glass?.lens === 'LENS14'
      ) {
        itemsToRemove.push('LENS40', 'LENS41')
      }

      refinements = refinements.filter((item) => !itemsToRemove.includes(item.id))

      return refinements
    },
    getAvailableIndexes(state) {
      if (!state.variant || state.variant.type === 'accessory' || !state.config) {
        return
      }

      let indexes = state.glassIndexes
      let itemsToRemove = []

      // on all items in indexes set property reomended to false
      //state.configuratorOptions[2][4].forEach((item) => (item.recommended = false))
      /*
      if material is syntethic
        - disable Spezial Mineral Gläser (1.7)
        - disable Spezial Mineral Gläser (1.8)
      */
      if (state.config.glass && state.config.glass.material === 'LENS36') {
        itemsToRemove.push('LENS33', 'LENS34')
      }

      /*
      If the Material is Mineral & the type is glasses with correction OR glasses without correction
        - Show “Regular Mineral Gläser (1.53)” LENS114
        - Show “Dünne Mineral Gläser (1.6)” LENS116
        - Show “Extra dünne Mineral Gläser (1.7)” LENS118
        - Show “Spezial Mineral Gläser (1.8)” LENS33
      */
      if (
        state.config.glass &&
        state.config.glass?.material === 'LENS37' &&
        (state.config.glassType === 'LENS1' || state.config.glassType === 'LENS2')
      ) {
        indexes = indexes.filter((item) =>
          ['LENS114', 'LENS116', 'LENS118', 'LENS33'].includes(item.id)
        )
        itemsToRemove = []
      }

      /*
      If the Material is Mineral & the type is sunglasses with correction OR sunglasses without correction
        - Show “Regular Mineral Sonnenbrillengläser (1.53) (1.53)” LENS115
        - Show “Dünne Mineral Sonnenbrillengläser (1.6)” LENS117
        - Show “Spezial Mineral Sonnenbrillengläser (1.8)” LENS119
      */
      if (
        state.config.glass &&
        state.config.glass?.material === 'LENS37' &&
        (state.config.glassType === 'LENS3' || state.config.glassType === 'LENS4')
      ) {
        indexes = indexes.filter((item) =>
          ['LENS115', 'LENS117', 'LENS119', 'LENS120'].includes(item.id)
        )
        itemsToRemove = []
      }

      /*
      If the Material is Synthetic & the type is glasses with correction OR glasses without correction
        - Show Optical Synthetic Index Options “Regular Gläser (1.53)”
        - Show Optical Synthetic Index Options “Dünne Gläser (1.6)”
        - Show Optical Synthetic Index Options “Extra dünne Gläser (1.7)”
        - Show Optical Synthetic Index Options “Spezial Gläser (1.74)”
      */
      if (
        state.config.glass &&
        state.config.glass.material === 'LENS36' &&
        (state.config.glassType === 'LENS1' || state.config.glassType === 'LENS2')
      ) {
        indexes = indexes.filter((item) =>
          ['LENS24', 'LENS26', 'LENS28', 'LENS30'].includes(item.id)
        )
        itemsToRemove = []
      }

      /*
       * If the Material is Synthetic & the type is Sunglasses with correction OR Sunglasses without correction
        - Show “Regular Sonnenbrillengläser (1.5)”
        - Show “Dünne Sonnenbrillengläser (1.6)”
        - Show  “Extra dünne Sonnenbrillengläser (1.67)”
       */
      if (
        state.config.glass &&
        state.config.glass.material === 'LENS36' &&
        (state.config.glassType === 'LENS3' || state.config.glassType === 'LENS4')
      ) {
        indexes = indexes.filter((item) => ['LENS25', 'LENS27', 'LENS29'].includes(item.id))
        itemsToRemove = []
      }

      /*
      If lens type is Arbeitsplatzgläser Standard (Type A):
      If lens type is Arbeitsplatzgläser Standard (Type B):
      If lens type is Bifokale Gläser:
        - hide Extra dünne Gläser (1.67)
        - hide Extra dünne Sonnenbrillengläser (1.67)
        - hide Spezial Gläser (1.74)
      */
      if (
        (state.config.prescription && state.config.glass?.lens === 'LENS13') ||
        state.config.glass?.lens === 'LENS14' ||
        state.config.prescription?.type === 'LENS20'
      ) {
        itemsToRemove.push('LENS28', 'LENS29', 'LENS30')
      }

      /*
      If lens type is Bifokal and Material is Mineral
      - hide Extra dünne Mineral Gläser (1.7)
      - hide Spezial Mineral Sonnenbrillengläser (1.8)
      - hide Spezial Mineral Gläser (1.8)
      */
      if (
        state.config.prescription?.type === 'LENS20' &&
        state.config.glass.material === 'LENS37'
      ) {
        itemsToRemove.push('LENS118', 'LENS119', 'LENS33')

        if (state.config.glassType === 'LENS2') {
          itemsToRemove.push('LENS33', 'LENS118')
        }
      }

      /*
      If lens type is Gleitsichtgläser Standard:
        - hide Spezial Gläser (1.74)
      */
      if (state.config.prescription && state.config.glass?.lens === 'LENS17') {
        itemsToRemove.push('LENS30')

        if (state.config.glass.index === 'LENS30') {
          this.removeSelectedOption('LENS30', 4)
        }
      }

      /*
      If type is Gleitsichtgläser AND material is Mineral
       - hide Regular Mineral Gläser (1.53)
       - hide Regular Mineral Sonnenbrillengläser (1.53)
      */
      if (
        state.config.prescription &&
        state.config.prescription.type === 'LENS113' &&
        state.config.glass.material === 'LENS37'
      ) {
        itemsToRemove.push('LENS114', 'LENS115')
      }

      /*
      If material is Mineral AND prescription type (no SKU Option) is Arbeitsplatzgläser
      - hide Regular Mineral Gläser (1.53)
      */
      if (
        state.config.glass?.material === 'LENS37' &&
        state.config.prescription?.type === 'LENS112'
      ) {
        itemsToRemove.push('LENS114')
      }

      /*
      If type is Sonnenbrille mit Korrektur AND Lens Vision is Singlevision Reading OR Singlevision Distance AND material is Mineral
       - hide Extra dünne Mineral Gläser (1.7) LENS118
      */

      if (
        ['LENS7', 'LENS6'].includes(state.config.prescription?.type) &&
        ['LENS4'].includes(state.config.glassType) &&
        state.config.glass?.material === 'LENS37'
      ) {
        itemsToRemove.push('LENS118', 'LENS120')
      }

      /*
      If type is Singlevision Reading OR Singlevision Distance AND Mitteldickenreduktion is selected
        - disable 1,74 Index
      */
      if (
        (state.config.glassType === 'LENS4' && state.config.prescription?.type === 'LENS112') ||
        (state.config.glassType === 'LENS4' && state.config.glass?.lens === 'LENS8')
      ) {
        if (state.config.glass?.material === 'LENS37') {
          itemsToRemove.push('LENS115')
        }
      }

      /*
      If type is Singlevision Reading OR Singlevision Distance AND material is Mineral
        - hide Extra dünne Mineral Gläser (1.7)
      */
      if (
        ['LENS6', 'LENS7'].includes(state.config.prescription?.type) &&
        state.config.glass.material === 'LENS37'
      ) {
        // hide LENS118
        itemsToRemove.push('LENS118')
      }

      // if material is LENS37 and state.config.glassType is LENS2 or LENS4
      if (
        state.config.glass?.material === 'LENS37' &&
        ['LENS2', 'LENS4'].includes(state.config.glassType) &&
        state.variant.strongCurvature
      ) {
        state.configuratorOptions[2][4]
          .filter((o) => o.id === 'LENS114' || o.id === 'LENS115')
          .forEach((o) => (o.disabled = true))
      } else {
        state.configuratorOptions[2][4]
          .filter((o) => o.id === 'LENS114' || o.id === 'LENS115')
          .forEach((o) => (o.disabled = false))
      }

      indexes = indexes.filter((item) => !itemsToRemove.includes(item.id))

      /* if Strong curvature
        - hide 1.5 Index
        - recommend regular index
      else
        - show 1.5 Index
        - recommend thin index
      */
      if (state.variant.strongCurvature === true) {
        this.addTooltip(
          'LENS24',
          'Due to the prescription and the large or flat frame, the Index Thin 1,6 needs to be selected'
        )
        this.addTooltip(
          'LENS25',
          'Due to the prescription and the large or flat frame, the Index Thin 1,6 needs to be selected'
        )

        state.configuratorOptions[2][4]
          .filter((o) => o.id === 'LENS24' || o.id === 'LENS25')
          .forEach((o) => (o.disabled = true))
      } else {
        state.configuratorOptions[2][4]
          .filter((o) => o.id === 'LENS24' || o.id === 'LENS25')
          .forEach((o) => (o.disabled = false))
      }

      return indexes
    },
    getAvailableColours(state) {
      if (!state.variant || state.variant.type === 'accessory' || !state.config) {
        return
      }

      let colours = []
      let itemsToRemove = []
      let resetColourClassifications = false

      /*if (state.hiddenSections.glassColour) {
        return []
      }*/

      if (state.config.glassType === 'LENS1') {
        state.glassColours.forEach((item) => {
          if (item.compatible_type.includes('Glasses with Correction')) {
            colours.push(item)
          }
        })
      }

      if (state.config.glassType === 'LENS2') {
        state.glassColours.forEach((item) => {
          if (item.compatible_type.includes('Glasses with Correction')) {
            colours.push(item)
          }
        })
      }

      if (state.config.glassType === 'LENS3') {
        state.glassColours.forEach((item) => {
          if (item.compatible_type.includes('Sunglasses without Correction')) {
            colours.push(item)
          }
        })
      }

      if (state.config.glassType === 'LENS4') {
        state.glassColours.forEach((item) => {
          if (item.compatible_type.includes('Sunglasses with Correction')) {
            colours.push(item)
          }
        })
      }

      if (state.config.glass && state.config.glass.material) {
        if (state.config.glass.material === 'LENS36') {
          colours = colours.filter((item) => item.compatible_material.includes('Synthetic'))
        }
        if (state.config.glass.material === 'LENS37') {
          colours = colours.filter((item) => item.compatible_material.includes('Mineral'))
        }
      }

      /*
      If the 'Veredlung' is Premium-Blaulichtfilter OR Blaulichtfilter
        - hide Getönte Einstärkengläser Grey (12%)
        - hide Getönte Mehrstärkengläser Grey (12%)
        - hide Getönte Einstärkengläser Grey (10-20%)
        - hide Getönte Mehrstärkengläser Grey (10-20%)
        - hide Getönte Einstärkengläser Blue (10-20%)
        - hide Getönte Mehrstärkengläser Blue (10-20%)
        - hide Getönte Einstärkengläser Brown (10-20%)
        - hide Getönte Mehrstärkengläser Brown (10-20%)
        - hide Selbsttönende Gläser Grey
        - hide Selbsttönende Gläser Brown
      */
      if (
        (state.config.glass && state.config.glass?.refinement === 'LENS40') ||
        state.config.glass?.refinement === 'LENS41'
      ) {
        itemsToRemove.push(
          'LENS42',
          'LENS43',
          'LENS46',
          'LENS47',
          'LENS48',
          'LENS49',
          'LENS50',
          'LENS51',
          'LENS52',
          'LENS53',
          'LENS54',
          'LENS55'
        )
      }

      /*
      If lens type is Bifokale Gläser AND lens index is Dünne Gläser (1.6):
        - hide Selbsttönende Gläser Grey
        - hide Selbsttönende Gläser Brown
      */
      if (
        state.config.prescription &&
        state.config.glass &&
        state.config.prescription.type === 'LENS20' &&
        state.config.glass?.index === 'LENS26'
      ) {
        itemsToRemove.push('LENS42', 'LENS43')
      }

      /*
      If lens type is Bifokale Gläser:
        - hide Getönte Mehrstärkengläser Grey (10-20%)
        - hide Getönte Mehrstärkengläser Blue (10-20%)
        - hide Getönte Mehrstärkengläser Brown (10-20%)
      */
      if (
        state.config.prescription &&
        state.config.glass &&
        state.config.prescription.type === 'LENS20'
      ) {
        itemsToRemove.push('LENS53', 'LENS55')
      }

      /*
      If lens type is Arbeitsplatzgläser Standard (Type A):
      If lens type is Arbeitsplatzgläser Standard (Type B):
        - hide Selbsttönende Gläser Grey
        - hide Selbsttönende Gläser Brown
        - disable mineral

      If the Material of the SKU is titanium or beta-titanium
        - disable mineral
      */
      const isWorkplaceGlasses =
        state.config.glass?.lens === 'LENS13' || state.config.glass?.lens === 'LENS14'
      const isTitaniumMaterial =
        state.variant && ['Titanium', 'Beta Titanium'].includes(state.variant?.material)
      const shouldDisableMineralGlasses =
        isWorkplaceGlasses ||
        isTitaniumMaterial ||
        ['LENS20', 'LENS10', 'LENS11', 'LENS12'].includes(state.config.prescription?.type)

      if (isWorkplaceGlasses) {
        itemsToRemove.push('LENS42', 'LENS43')

        if (['LENS42', 'LENS43'].includes(state.config.glass.colour)) {
          resetColourClassifications = true
          this.removeSelectedOption(state.config.glass.colour, 4)
        }
      }

      const mineralGlassMaterial = state.glassMaterials.find((item) => item.id === 'LENS37')
      if (mineralGlassMaterial) {
        mineralGlassMaterial.disabled = shouldDisableMineralGlasses
      }

      /*
      If type is Sonnenbrille mit Korrektur AND Lens Vision is Arbeitsplatzgläser (LENS112)
      If type is Sunglasses with Correction AND Individual Singlevision is selected
        - hide Polarized Colours
      */
      if (
        (state.config.glassType === 'LENS4' && state.config.prescription?.type === 'LENS112') ||
        (state.config.glassType === 'LENS4' && state.config.glass?.lens === 'LENS8')
      ) {
        itemsToRemove.push('LENS106', 'LENS107', 'LENS108', 'LENS109', 'LENS110', 'LENS111')
      }

      /*
      If the 'Veredlung' is Premium-Blaulichtfilter OR Blaulichtfilter
      - hide Getönte Einstärkengläser Grey (12%)
      - hide Getönte Mehrstärkengläser Grey (12%)
      - hide Getönte Einstärkengläser Grey (10-20%)
      - hide Getönte Mehrstärkengläser Grey (10-20%)
      - hide Getönte Einstärkengläser Blue (10-20%)
      - hide Getönte Mehrstärkengläser Blue (10-20%)
      - hide Getönte Einstärkengläser Brown (10-20%)
      - hide Getönte Mehrstärkengläser Brown (10-20%
      - hide Getönte Einstärkengläser Brown (12%)
      - hide Getönte Mehrstärkengläser Brown (25%)
      - hide Selbsttönende Gläser Grey
      - hide Selbsttönende Gläser Brown
      */
      if (
        (state.config.glass && state.config.glass?.refinement === 'LENS40') ||
        state.config.glass?.refinement === 'LENS41'
      ) {
        itemsToRemove.push(
          'LENS42',
          'LENS43',
          'LENS44',
          'LENS45',
          'LENS46',
          'LENS47',
          'LENS52',
          'LENS53',
          'LENS54',
          'LENS55',
          'LENS56'
        )
      }

      /*
      If type is Glasses without Correction
      - hide Getönte Mehrstärkengläser Grey (12%)
      - hide Getönte Mehrstärkengläser Brown (12%)
      - hide Getönte Mehrstärkengläser Grey (25%)
      - hide Getönte Mehrstärkengläser Brown (25%)
      - hide Getönte Mehrstärkengläser Grey (10-20%)
      - hide Getönte Mehrstärkengläser Blue (10-20%)
      - hide Getönte Mehrstärkengläser Brown (10-20%)
      */
      if (state.config.glassType === 'LENS1') {
        itemsToRemove.push('LENS45', 'LENS47', 'LENS49', 'LENS51', 'LENS53', 'LENS55')
      }

      // If Type is Lenses with Correction AND Lens Type is Bifocal AND material is Mineral
      // hide Getönte Mehrstärkengläser Grey (12%) LENS47 and hide Getönte Mehrstärkengläser Grey (25%) LENS51
      if (
        state.config.glassType === 'LENS2' &&
        state.config.prescription?.type === 'LENS20' &&
        state.config.glass?.material === 'LENS37'
      ) {
        itemsToRemove.push('LENS46', 'LENS50')
      }

      // if material is not mineral and glasses arre not with correction and prescription type is not bikokal
      if (
        state.config.glass?.material !== 'LENS37' &&
        state.config.glassType !== 'LENS2' &&
        state.config.glassType !== 'LENS5' &&
        state.config.prescription?.type &&
        state.config.prescription.type !== 'LENS20'
      ) {
        itemsToRemove.push('LENS46', 'LENS50')
      }

      colours = colours.filter((item) => !itemsToRemove.includes(item.id))

      /* only return colours that are compatible with the selected index */
      if (state.config.glass && state.config.glass?.index !== '') {
        colours = colours.filter((item) => {
          return item.compatible_index
            .split(',')
            .map((item) => item.trim())
            .includes(state.config.glass.index)
        })
      }

      /* only return colours that are compatible with the prescription type*/
      if (state.config.prescription?.type) {
        colours = colours.filter((item) => {
          if (!item.compatible_lens_vision) {
            return item
          }

          return item.compatible_lens_vision
            .split(',')
            .map((item) => item.trim())
            .includes(state.config.prescription.type)
        })
      }

      if (resetColourClassifications) {
        const classifications = state.getAvaliableColourClassifiction
        const currentClassification = state.currentColourClassification

        /* set state.currentColourClassification to first available classification that is not equal to currentClassification */
        if (classifications && classifications.length > 0) {
          const newClassification = classifications.filter((item) => item !== currentClassification)
          state.currentColourClassification = newClassification[0]
        }
      }

      return colours
    },
    getAvaliableColourClassifiction(state) {
      let classifications = []
      if (state.variant && state.variant.type === 'accessory') {
        return
      }

      const colours = state.getAvailableColours
      if (colours && colours.length > 0) {
        classifications = [...new Set(colours.map((item) => item.classification))]
      }
      return classifications
    }
  },
  actions: {
    addTooltip(id, text) {
      if (this.getTooltip(id)) {
        // update existing tooltip
        this.tooltips = this.tooltips.map((item) => {
          if (item.id === id) {
            item.text = text
          }
          return item
        })
      } else {
        this.tooltips.push({ id: id, text: text })
      }
    },
    removeTooltip(id) {
      this.tooltips = this.tooltips.filter((item) => item.id !== id)
    },
    getTooltip(id) {
      const t = this.tooltips.filter((item) => item.id === id)
      if (t && t[0]) {
        return t[0].text
      }
    },
    setConfigurationSchema(config) {
      this.config = config
    },
    addConfiguratorOptions(options) {
      this.configuratorOptions = options
    },
    addConfiguratorOptions3D(options) {
      this.configuratorOptions3D = options
    },
    addSelectedOption(id, step) {
      let skuItem = this.getFlatOptions.filter((o) => {
        return o.id === id
      })

      if (skuItem.length === 0) {
        let foundCase = this.availableCases.find((o) => {
          return o.sku.code === id
        })

        if (foundCase) {
          // for each item in foundCase.sku.metadata.title object add "bla" to the value.
          const title = Object.keys(foundCase.sku.metadata.title).reduce((acc, key) => {
            acc[key] =
              `${foundCase.sku.metadata.title[key]} - ${foundCase.sku.metadata.colour_name}`
            return acc
          }, {})

          skuItem = [
            {
              id: foundCase.sku.code,
              name: title,
              price: 0,
              reference: foundCase.sku.id,
              configuration_index: 'case'
            }
          ]
        }
      }

      // check if skuItem is already in selectedOptions
      const alreadySelected = this.selectedOptions[step].filter((o) => {
        return o.id === id
      })
      if (alreadySelected.length > 0 || skuItem.length === 0) {
        return
      }

      this.selectedOptions[step].push({
        id: skuItem[0].id,
        name: skuItem[0].name,
        price: skuItem[0].price,
        reference: skuItem[0].reference,
        configuration_index: skuItem[0].configuration_index,
        assembly_in_servicehub_only: skuItem[0].assembly_in_servicehub_only ? true : false
      })
    },
    removeSelectedOption(id, step) {
      if (id) {
        this.selectedOptions[step] = this.selectedOptions[step].filter((o) => {
          return o.id !== id
        })
      }
    },
    removeSelectedOptionsByCategory(step, category) {
      this.selectedOptions[step] = this.selectedOptions[step].filter((o) => {
        return o.configuration_index !== category
      })
    },
    clearNonMergableOptions() {
      this.selectedOptions[2] = []
      this.selectedOptions[3] = []
      this.selectedOptions[4] = []
      this.selectedOptions[5] = []
    },
    resetPrescription() {
      this.config.prescription = {
        id: null,
        type: '',
        prescription: {},
        originPrescription: {}
      }
      this.config.centering = {
        hsaGlass: null,
        hsaPrescription: null,
        fsw: null,
        inc: null,
        channel: null,
        viewHeightLeft: null,
        viewHeightRight: null
      }
    }
  }
})
