import { useDeviceStore } from '@/stores/device'
import featurePermissions from '@/featurePermissions'

let deviceStore

export function roleBasedAccess({ router }) {
  return {
    install(app) {
      // setup directive to allow/disallow only certain roles
      app.directive('access', async (el, { arg }) => {
        if (!deviceStore) {
          deviceStore = useDeviceStore()
        }
        const user = await deviceStore.getCurrentEmployee()
        if (hasAccess(user, featurePermissions[arg])) {
          enableElement(el)
        } else {
          disableElement(el)
        }
      })
      // block routes
      router.beforeEach(async (to) => {
        if (!deviceStore) {
          deviceStore = useDeviceStore()
        }
        if (to.meta?.permissions) {
          const user = await deviceStore.getCurrentEmployee()
          if (!hasAccess(user, featurePermissions[to.meta.permissions])) {
            router.replace({ name: 'home' })
          }
        }
      })
    }
  }
}

function hasAccess(user, permissions) {
  const access = []
  if (permissions) {
    const { accessIf, accessIfNot } = permissions
    if (Array.isArray(accessIf)) {
      access.push(accessIf.some((role) => user.role === role))
    }
    if (Array.isArray(accessIfNot)) {
      access.push(accessIfNot.every((role) => user.role !== role))
    }
  }
  return access.every(Boolean)
}

const CLASS_FORBIDDEN = 'forbidden'
function disableElement(el) {
  el?.classList?.add(CLASS_FORBIDDEN)
}

function enableElement(el) {
  el?.classList?.remove(CLASS_FORBIDDEN)
}
