// TODO: still few cases needs to be covered
// default error message should be added
export function getErrorMessage(error) {
  if (error?.message && !error?.errors?.length) {
    return error.message
  }

  if (error.errors?.length) {
    return error.errors.map((obj) => obj.detail)
  }

  return 'Something went wrong. Please try again later.'
}
