import CommerceLayer from '@commercelayer/sdk'
import * as clUtils from '@commercelayer/sdk-utils'
import { supabase } from '@/supabase'
import * as Sentry from '@sentry/vue'
import { fetchEdgeFunctionError } from '@/utils/supabase'

class CommercelayerError extends Error {
  constructor(message) {
    super(message)
    this.name = 'CommercelayerError'
  }
}

async function getCommerceToken(supabase, retry = true) {
  // Will throw an error if the user (employee table) has no live role assigned
  const { data, error } = await supabase.functions.invoke('auth-pos-commercelayer-jwt', {
    body: JSON.stringify({
      env: import.meta.env.VITE_WEB_ENV
    }),
    region: 'eu-central-1'
  })

  if (!error) {
    return data.accessToken
  } else {
    if (error.context?.status === 401 && retry) {
      const session = await supabase.auth.getSession()

      // if we have an active session, refresh the token
      if (session?.data?.session?.refresh_token) {
        const refreshedSession = await supabase.auth.refreshSession({
          refresh_token: session?.data?.session?.refresh_token
        })

        // try to get the token again with the refreshed session
        if (refreshedSession?.data?.session?.access_token) {
          return await getCommerceToken(supabase, false)
        }
      }

      // logout the user if the token is invalid
      await supabase.auth.signOut()
    }
    const errorMessage = await fetchEdgeFunctionError(error)
    Sentry.setContext('error', errorMessage)
    return
  }
}

async function init() {
  try {
    const token = await getCommerceToken(supabase)

    if (!token) {
      return
    }

    const cl = CommerceLayer({
      organization: import.meta.env.VITE_CL_ORG,
      accessToken: token
    })

    const onInvalidToken = async (input, init) => {
      const token = await getCommerceToken(supabase)
      cl.config({ accessToken: token })
      init.headers['Authorization'] = `Bearer ${token}`
      return await fetch(input, init)
    }

    // Fetch interceptor to handle 401 errors and refresh the token automatically and retry the request
    const customFetch = async (input, init) => {
      const res = await fetch(input, init)

      if (res.status === 401) {
        return await onInvalidToken(input, init)
      }
      return res
    }

    cl.config({ fetch: customFetch })

    cl.addResponseInterceptor(
      (successResponse) => successResponse,
      (errorResponse) => {
        if (errorResponse?.errors?.length > 0) {
          const errorApi = errorResponse.errors[0]
          Sentry.setContext('commercelayer-sdk-errors', {
            errors: errorResponse.errors
          })
          // convert the error to a CommercelayerError to have a consistent error handling
          return new CommercelayerError(`${errorApi.code}: ${errorApi.detail}`)
        } else {
          return errorResponse
        }
      }
    )

    return cl
  } catch (e) {
    Sentry.captureException(e)
    throw new Error(
      `Error fetching CommerceLayer TOKEN: ${import.meta.env.VITE_WEB_ENV.toUpperCase()}`
    )
  }
}

export default async function () {
  const cl = await init()
  clUtils.CommerceLayerUtils(cl)
  return { cl, clUtils }
}
