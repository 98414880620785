<template>
  <div ref="customerCartDialog">
    <sl-dialog
      :open="isLoadingCustomer"
      class="customer-loading-dialog"
      style="--width: 240px"
      no-header
      @sl-request-close="preventClosingDialogOnOverlay"
      ><sl-spinner style="font-size: 3rem; --track-width: 4px"></sl-spinner>
      <div>{{ $t('globals.loading') }}...</div>
    </sl-dialog>
    <sl-dialog
      :open="isChooseCartVisible"
      :label="$t('cart.dialog.choose_cart.title')"
      style="--width: 744px"
      @sl-request-close="$emit('closeDialog')"
    >
      <ul v-if="customer.carts" class="carts">
        <li v-for="cart in customer.carts.slice(0, 3)" :key="cart.id" class="cart">
          <div class="cart__image-wrapper">
            <img v-if="getImageUrl(cart)" class="cart__image" :src="imageUrl" alt="frame image" />
          </div>
          <div class="cart__frame-and-skus-count">
            <template v-if="getFrameTitle(cart)">
              {{ getFrameTitle(cart) }}
              <template v-if="cart.skus_count >= 2">, +{{ cart.skus_count - 1 }}</template>
              <br
            /></template>
            <sl-badge v-if="cartHasAftersaleGiftcard(cart)">Aftersales</sl-badge>
          </div>
          <div class="cart__market-reference">
            <p v-if="cart.market?.code">{{ cart.market.code }}</p>
          </div>
          <div class="cart__order-create-date">{{ formatISODateToDDMMYYYY(cart.created_at) }}</div>
          <div class="text-right">
            <sl-button
              class="cart__select-cart-button"
              size="medium"
              variant="primary"
              @click="setCart(cart)"
              >{{ $t('cart.dialog.choose_cart.button.open_cart') }}</sl-button
            >
          </div>
        </li>
      </ul>
      <div slot="footer" class="footer-actions">
        <sl-button variant="default" @click="$emit('closeDialog')">{{
          $t('cart.dialog.choose_cart.button.continue_without')
        }}</sl-button>
      </div>
    </sl-dialog>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useCustomerStore } from '@/stores/customer'
import { useNotification } from '@/composables/useNotification.js'
import { useDeviceStore } from '@/stores/device.js'
import { formatISODateToDDMMYYYY } from '@/utils/dateTime.js'

const customerStore = useCustomerStore()
const deviceStore = useDeviceStore()
const notification = useNotification()
const customer = computed(() => customerStore.getCustomer)
const language = computed(() => deviceStore.settings.language)
const customerCartDialog = ref()
let imageUrl = null

const emit = defineEmits(['closeDialog'])

defineProps({
  isChooseCartVisible: {
    type: Boolean,
    default: false
  },
  isLoadingCustomer: {
    type: Boolean,
    default: false
  }
})

function setCart(cart) {
  customerStore.setCurrentCart(cart)
  notification.showNotification({
    message: 'Cart has been loaded.',
    icon: 'person-down'
  })
  emit('closeDialog')
}

function cartHasAftersaleGiftcard(cart) {
  // check if cart has any attachment where reference starts with "As/".
  return cart.attachments?.some((attachment) =>
    attachment.metadata?.gift_card_reference?.startsWith('AS/')
  )
}

function preventClosingDialogOnOverlay(event) {
  event.stopPropagation()
  if (['overlay', 'keyboard'].includes(event.detail.source)) {
    event.preventDefault()
  }
}

function getImageUrl(cart) {
  const cartSorted = sortLineItems(JSON.parse(JSON.stringify(cart)))
  const lineItem = cartSorted.line_items.find((lineItem) =>
    ['frame', 'customMadeFrame', 'lens', 'accessory', 'sparePart'].includes(
      lineItem?.item?.metadata?.type
    )
  )
  const url = lineItem?.item?.image_url?.replace('?h=500', '?h=80')

  if (url) {
    imageUrl = `${url}?w=80&fit=fill&crop=center&dpr=2&bg=F6F6F6&q=70`
  }

  return !!url
}

function getFrameTitle(cart) {
  const cartSorted = sortLineItems(JSON.parse(JSON.stringify(cart)))
  const frameLineItem = cartSorted.line_items.find((lineItem) =>
    ['frame', 'customMadeFrame', 'lens', 'accessory'].includes(lineItem?.item?.metadata?.type)
  )
  if (frameLineItem) {
    return frameLineItem?.item?.metadata?.title[language.value]
  }
  return null
}

function sortLineItems(cart) {
  cart.line_items?.sort((a, b) => {
    if (a?.item?.metadata?.type === 'frame' || a?.item?.metadata?.type === 'customMadeFrame') {
      return -1
    }
    if (b?.item?.metadata?.type === 'frame' || b?.item?.metadata?.type === 'customMadeFrame') {
      return 1
    }
    return 0
  })

  return cart
}
</script>

<style src="./CustomerCartDialog.css" scoped />
