<template>
  <div></div>
</template>

<script setup>
import { useDeviceStore } from '@/stores/device'
import '@shoelace-style/shoelace/dist/components/alert/alert'
import '@shoelace-style/shoelace/dist/components/icon/icon'
import { onMounted, inject } from 'vue'
import { useNotification } from '@/composables/useNotification.js'
const supabase = inject('supabase')
const deviceStore = useDeviceStore()
const notification = useNotification()

onMounted(() => {
  notification.setNotificationEvent()
})

if (deviceStore.settings.warehouse) {
  const channel = supabase.channel(deviceStore.settings.warehouse, {
    config: {
      broadcast: {
        self: true
      }
    }
  })
  channel
    .on('broadcast', { event: 'updateEmployeeList' }, (payload) => {
      deviceStore.setStoreEmployees(payload.list)
    })
    .on('broadcast', { event: 'showNotification' }, (payload) => {
      notification.notify(payload)
    })
    .subscribe()
}
</script>
