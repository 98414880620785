export default {
  promotions: {
    accessIf: ['admin', 'customer_solutions', 'retail_manager', 'marketing']
  },
  stockManagement: {},
  createStockInLocation: {
    accessIfNot: ['sales']
  },
  inventory: {},
  convertToFrame: {},
  anonymiseUser: {
    accessIf: ['customer_solutions', 'admin']
  }
}
