import { defineStore } from 'pinia'

export const usePaymentStore = defineStore('payment', {
  state: () => ({
    payment: {
      orderNumber: '',
      type: '',
      amount: '',
      currencyCode: ''
    },
    terminals: [],
    expectedDeliverySlips: 0
  }),
  getters: {
    getPayment: (state) => state.payment,
    getTerminals: (state) => state.terminals
  }
})
