import './assets/css/reset.css'
import './assets/css/main.css'
import './assets/css/typography.css'
import './assets/css/variables.css'

// App
import { supabase } from '@/supabase'
import messages from '@intlify/unplugin-vue-i18n/messages'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import { createI18n } from 'vue-i18n'
import InstantSearch from 'vue-instantsearch/vue3/es'
import App from './App.vue'
import router from './router'
import getCommerceLayer from '@/commercelayer'
import { roleBasedAccess } from '@/plugins/permissions'
import * as Sentry from '@sentry/vue'

// Shoelace
import '@shoelace-style/shoelace/dist/themes/light.css'
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path'
import './assets/css/shoelace.css'

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.15.1/dist/')

// Table Styles
import '@/assets/css/table.css'
import '@/assets/css/vue3-easy-data-table.css'

// Setup i18n
const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'de',
  fallbackLocale: 'de',
  availableLocales: ['de', 'ch_de', 'en', 'fr'],
  langDir: 'locales/',
  messages: messages
})

// Create app
const app = createApp(App)

// Sentry
Sentry.init({
  app,
  attachStacktrace: true,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  // eslint-disable-next-line no-undef
  release: __APP_VERSION__,
  environment: import.meta.env.VITE_WEB_ENV,
  // integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracePropagationTargets: ['https://wings.shopviu.com', 'https://wings-test.shopviu.com'],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0, // Disable Session Replay
  replaysOnErrorSampleRate: 0, // Disable Session Replay,
  normalizeDepth: 5
})

// eslint-disable-next-line no-undef
Sentry.setTag('app_version', __APP_VERSION__)

let marketSettings = localStorage.getItem('marketSettings')

try {
  if (marketSettings) {
    marketSettings = JSON.parse(marketSettings)

    if (marketSettings && marketSettings.warehouseReference) {
      Sentry.setTag('marketSettings', marketSettings.warehouseReference)
    } else {
      // delete localstorage entry where key is marketSettings.
      localStorage.removeItem('marketSettings')
      // Handle case where warehouseReference is missing
      alert(
        'Error: Irgendwas ist schiefgelaufen. Bitte öffne das Hauptmenü und selektieren den Store neu.'
      )
    }
  }
} catch (error) {
  console.error('Error parsing marketSettings JSON:', error)
}
async function setup() {
  const initErrors = []
  try {
    const { cl, clUtils } = await getCommerceLayer()
    app.provide('cl', cl)
    app.provide('clUtils', clUtils)
  } catch (e) {
    await supabase.auth.signOut()
  }

  try {
    app.provide('supabase', supabase)
    app.provide('Sentry', Sentry)

    app.use(createPinia())
    app.use(router)
    app.use(i18n)
    app.use(InstantSearch)
    app.use(roleBasedAccess({ router }))
  } catch (e) {
    initErrors.push({
      message: 'error.init.general'
    })
    console.log(e)
  }

  app.provide('initErrors', initErrors)
  app.mount('#app')
}

setup()
