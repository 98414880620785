export function useNotification() {
  function showNotification({
    message = 'Default message text',
    variant = 'success',
    icon = 'info-circle',
    duration = 6000,
    closable = true,
    bubbles = true
  }) {
    const app = document.getElementById('app')
    const event = new CustomEvent('showNotification', {
      bubbles,
      detail: {
        message,
        variant,
        duration,
        icon,
        closable
      }
    })
    app.dispatchEvent(event)
  }

  function notify(broadcastMessage) {
    const defaultMessage = {
      message: 'Message Text',
      variant: 'primary',
      icon: 'info-circle',
      duration: 3000,
      closable: true
    }
    const { variant, icon, duration, closable, message } = {
      ...defaultMessage,
      ...broadcastMessage
    }
    const alert = Object.assign(document.createElement('sl-alert'), {
      icon,
      variant,
      closable,
      duration,
      innerHTML: `
        <sl-icon name="${icon}" slot="icon"></sl-icon>
        ${escapeHtml(message)}
      `
    })

    document.body.append(alert)
    return alert.toast()
  }
  function escapeHtml(html) {
    const div = document.createElement('div')
    div.innerHTML = html
    return div.innerHTML
  }

  function setNotificationEvent() {
    window.addEventListener('showNotification', (e) => notify(e.detail))
  }

  return { notify, showNotification, setNotificationEvent }
}
