<template>
  <sl-dialog
    :open="uiStore.showDeviceMarketSettingsDialog"
    class="market-dialog"
    label="Market Information"
    @sl-request-close="preventClosingDialog"
  >
    <div v-if="uiStore.showDeviceMarketSettingsDialog">
      <form-autocomplete
        v-model="marketSettings.warehouse"
        :error="marketSettingsErrors.warehouse"
        :options="marketsList"
        label="Select store"
        option-key="name"
        option-key2="code"
        data-testid="select-store-input"
        @blur="handleFieldValidation('warehouse')"
        @keyup="handleFieldValidation('warehouse')"
      />
      <sl-select
        v-model="marketSettings.deviceType"
        class="select-device-type"
        label="Select device type"
        data-testid="select-device-type-dropdown"
        @sl-change="handleDeviceTypeChange"
      >
        <sl-option
          v-for="deviceType in userDeviceType"
          :key="deviceType"
          :data-testid="`select-device-type-dropdown-${deviceType.toLowerCase()}`"
          :value="deviceType"
        >
          {{ deviceType }}
        </sl-option>
      </sl-select>
      <form-input
        v-if="marketSettings.deviceType === 'Store'"
        v-model="marketSettings.deviceId"
        :error="marketSettingsErrors.deviceId"
        label="Device ID"
        data-testid="input-deviceID"
        @blur="handleFieldValidation('deviceId')"
        @keyup="handleFieldValidation('deviceId')"
      />
    </div>
    <sl-button
      slot="footer"
      class="market-dialog-footer-button"
      :disabled="!isFormValid || !isValidWarehouseSelection"
      variant="primary"
      data-testid="market-information-submit-button"
      @click="handleCloseDialog"
    >
      {{ $t('globals.save') }}
    </sl-button>
  </sl-dialog>
</template>

<script setup>
import FormAutocomplete from '@/components/Form/FormAutocomplete.vue'
import FormInput from '@/components/Form/FormInput/FormInput.vue'
import { useEmployee } from '@/composables/useEmployee.js'
import { useNotification } from '@/composables/useNotification.js'
import { MODE } from '@/constants/device.js'
import { CURRENCY_CODE } from '@/constants/payment.js'
import { useDeviceStore } from '@/stores/device'
import { useUiStore } from '@/stores/ui.js'
import { getErrorMessage } from '@/utils/error.js'
import '@shoelace-style/shoelace/dist/components/dialog/dialog'
import '@shoelace-style/shoelace/dist/components/option/option'
import '@shoelace-style/shoelace/dist/components/select/select'
import { watchDebounced } from '@vueuse/core'
import { computed, inject, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import * as yup from 'yup'

const cl = inject('cl')
const Sentry = inject('Sentry')
const supabase = inject('supabase')
const deviceStore = useDeviceStore()
const uiStore = useUiStore()
const notification = useNotification()
const userDeviceType = ['Store', 'Personal']
const { addEmployee } = useEmployee()
const router = useRouter()

// used to check if user selected valid warehouse
const isValidWarehouseSelection = ref(true)
const marketsList = ref([])

const isFormValid = computed(() => {
  try {
    validationSchema.validateSync(marketSettings.value, { abortEarly: false })
    return true
  } catch (error) {
    return false
  }
})

const validationSchema = yup.object().shape({
  warehouse: yup.string().required('Please select store.'),
  deviceType: yup.string().required(),
  deviceId: yup.string().when('deviceType', {
    is: (value) => value === 'Store',
    then: (schema) => schema.required('Please enter your device type'),
    otherwise: (schema) => schema
  })
})

const marketSettings = ref({
  marketId: '',
  warehouse: '',
  warehouseReference: '',
  deviceType: '',
  deviceId: '',
  countryCode: '',
  currencyCode: '',
  language: '',
  name: '',
  storeAddressId: '',
  serviceHubId: '',
  tags: [],
  mode: MODE.STORE
})

const marketSettingsErrors = ref({
  warehouse: '',
  deviceType: '',
  deviceId: ''
})

onMounted(async () => {
  setDialogVisibility()
  deviceStore.setCurrentEmployee({})
})

function preventClosingDialog(event) {
  event.stopPropagation()
  if (['overlay', 'keyboard'].includes(event.detail.source)) {
    event.preventDefault()
  }
}

async function handleFieldValidation(fieldName) {
  try {
    await validationSchema.validateAt(fieldName, marketSettings.value)
    marketSettingsErrors.value[fieldName] = ''
  } catch (error) {
    marketSettingsErrors.value[fieldName] = error.message
  }
}

function handleDeviceTypeChange(event) {
  marketSettings.value.deviceType = event.target.value
  if (marketSettings.value.deviceType === 'Personal') {
    marketSettingsErrors.value.deviceId = ''
  }
}

async function getMarketsOptions() {
  if (marketSettings.value.warehouse === '') {
    marketsList.value = []
    return
  }

  try {
    marketsList.value = await getMarketsList('name_or_code_cont_any')

    isValidWarehouseSelection.value = false

    if (marketsList.value?.length === 0) {
      marketSettingsErrors.value.warehouse = "Store doesn't exist"
      return
    }

    // reset markets list if the value is selected from the list of options
    // check if marketSettings.value.warehouse exists in marketList.value. compare by property name.
    if (!marketsList.value?.some((market) => market.name === marketSettings.value.warehouse)) {
      isValidWarehouseSelection.value = false
      return
    }

    marketsList.value = []
    isValidWarehouseSelection.value = true
  } catch (e) {
    throw new Error(e)
  }
}

async function setMarketSettingsFromWarehouse() {
  if (marketSettings.value.warehouse.length < 2) {
    return
  }

  try {
    const warehouse = await getMarketsList('name_eq')

    if (warehouse.length) {
      const tagsList = await cl.tags.list()

      const stockLocations = await cl.stock_locations.list({
        include: ['address'],
        filters: {
          code_eq: warehouse[0].code
        }
      })

      const countryCode = warehouse[0]?.merchant?.address?.country_code ?? 'NaN'

      const serviceHubId = await cl.stock_locations.list({
        filters: {
          code_eq: `SH${countryCode.toUpperCase()}`
        }
      })

      let mode = MODE.STORE
      if (['SHAT', 'SHCH', 'SHDE'].includes(warehouse[0].code)) {
        mode = MODE.SERVICE_HUB
      }

      marketSettings.value.stockLocationId = stockLocations[0].id
      marketSettings.value.storeAddressId = stockLocations[0].address.id
      marketSettings.value.warehouseReference = warehouse[0].code
      marketSettings.value.countryCode = warehouse[0]?.merchant?.address?.country_code.toLowerCase()
      marketSettings.value.currencyCode = warehouse[0]?.price_list?.currency_code
      marketSettings.value.language = warehouse[0]?.metadata.language.toLowerCase()
      marketSettings.value.marketId = warehouse[0]?.id
      marketSettings.value.name = warehouse[0]?.name
      marketSettings.value.serviceHubId = serviceHubId[0]?.id
      marketSettings.value.tags = tagsList
      marketSettings.value.mode = mode

      // check if the current employee is a store employee
      // On the very first login the user is not set in the store_employees table
      if (deviceStore.settings?.currentEmployee?.id) {
        await supabase
          .from('store_employees')
          .upsert({
            store_warehouse: warehouse[0].code,
            employee_id: deviceStore.settings?.currentEmployee?.id,
            app_environment: import.meta.env.VITE_WEB_ENV
          })
          .select()
      }
    }
  } catch (e) {
    throw new Error(e)
  }
}

async function getMarketsList(filterName) {
  try {
    let markets = await cl.markets.list({
      include: ['price_list', 'merchant.address'],
      filters: {
        [filterName]: `${marketSettings.value.warehouse}`,
        metadata_jcont: '{"status":"OPEN"}'
      }
    })
    // remove entry where code === BRN1
    markets = markets.filter((market) => market.code !== 'BRN1')

    return markets
  } catch (error) {
    notification.showNotification({
      message: getErrorMessage(error),
      variant: 'danger'
    })
  }
}

function updateSettingsStore() {
  if (marketSettings.value) {
    deviceStore.$patch({
      settings: {
        stockLocationId: marketSettings.value.stockLocationId,
        marketId: marketSettings.value.marketId,
        warehouse: marketSettings.value.warehouseReference,
        deviceType: marketSettings.value.deviceType,
        deviceId: deviceStore.user.email,
        country: marketSettings.value.countryCode.toLowerCase(),
        currency: marketSettings.value.currencyCode,
        language: marketSettings.value.language.toLowerCase(),
        name: marketSettings.value.name,
        storeAddressId: marketSettings.value.storeAddressId,
        serviceHubId: marketSettings.value.serviceHubId,
        tags: marketSettings.value.tags
      }
    })

    // update Sentry tags
    Sentry.setTag('marketSettings', marketSettings.value.warehouseReference)

    if (marketSettings.value.deviceType === 'Store') {
      deviceStore.$patch({
        settings: {
          deviceId: marketSettings.value.deviceId
        }
      })
    } else {
      deviceStore.setCurrentEmployee({})
      addEmployee(deviceStore.user.email)
    }
  }

  // if there is no device id set email
  if (!marketSettings.value.deviceId) {
    marketSettings.value.deviceId = deviceStore.user.email
  }
}

function handleCloseDialog() {
  updateSettingsStore()
  localStorage.setItem('marketSettings', JSON.stringify(marketSettings.value))
  uiStore.showDeviceMarketSettingsDialog = false
  router.push({ name: 'home' })
  notification.showNotification({
    message: 'Your settings have been updated successfully.'
  })
}

watchDebounced(
  () => marketSettings.value.warehouse,
  async (newVal, oldVal) => {
    if (newVal !== oldVal) {
      await Promise.all([getMarketsOptions(), setMarketSettingsFromWarehouse()])
    }
  },
  { debounce: 400, maxWait: 2000 }
)

function setDialogVisibility() {
  const marketSettings = localStorage.getItem('marketSettings')
  const parsedData = JSON.parse(marketSettings)

  if (parsedData && Object.keys(parsedData).length >= 3) {
    uiStore.showDeviceMarketSettingsDialog = false
  } else {
    resetToDefaultState()
    uiStore.showDeviceMarketSettingsDialog = true
  }
}

watch(
  () => uiStore.showDeviceMarketSettingsDialog,
  (newVal) => {
    if (newVal) {
      setDialogVisibility()
    }
  },
  { immediate: true }
)

function resetToDefaultState() {
  deviceStore.$patch({
    settings: {
      marketId: 'vjGRmhXNmo',
      warehouse: 'ZRH1',
      deviceType: '',
      deviceId: 'ipad123-zrh1',
      country: 'ch',
      currency: CURRENCY_CODE.EUR,
      storeAddressId: '',
      serviceHubId: 'ekjpouLQdn',
      tags: []
    }
  })
  marketSettings.value.warehouse = ''
  marketSettings.value.deviceType = ''
  marketSettings.value.deviceId = ''
}
</script>

<style lang="postcss" scoped>
.select-device-type {
  margin-bottom: var(--sl-spacing-small);
}

.market-dialog {
  &::part(close-button) {
    display: none;
  }

  &::part(body) {
    padding: 0 var(--sl-spacing-x-large);
    min-height: 180px;
  }
}

sl-dialog {
  &::part(footer) {
    align-self: flex-end;
  }
}
</style>
